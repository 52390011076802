<template>
  <div v-if="updating">
    <div class="loader-container">
      <div class="row justify-content-center">
        <div class="col-md-4">
          <div class="loader">Loading...</div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="mb-4">
    <!-- Modal  -->
    <div v-if="editLeaseModal" class="modal fade in show modal-active modal-open" id="leaseModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Change {{ editLease.leaseOrder.product_name }} status</h4>
            <button type="button" class="close" v-on:click="hideLeaseModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4">
                <div>Lease status</div>
                <div v-if="editLease.leaseOrder.back_to_stock" class="inputfield price pl-2 pt-2" style="height: 42px;">Returned to stock</div>
                <v-select
                  v-else
                  :reduce="ls => ls.value"
                  :options="leaseStatuses"
                  :clearable="false"
                  v-model="editLease.leaseStatus"
                ></v-select>
              </div>
              <div class="col-md-4">
                <div>Lease notes</div>
                <v-select
                  multiple
                  label="label"
                  :reduce="ns => ns.value"
                  :options="noteStatusOptions"
                  v-model="editLease.leaseNoteStates"
                ></v-select>
              </div>
              <div class="col-md-4">
                <div>Written lease notes</div>
                <div><textarea rows=6 v-model="editLease.leaseNote" /></div>
              </div>
            </div>
          </div><!-- Modal body end -->
          <!-- Modal footer -->
          <div class="modal-footer">
              <div class="col-md-4"><button type="button" class="btn btn-lg btn-outline-primary btn-block mt-3" v-on:click="hideLeaseModal()">Cancel</button></div>
              <div class="col-md-4"><button type="button" class="btn btn-lg btn-primary btn-block mt-3" v-on:click="updateStatus()">Save changes</button></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="editLeaseModal" class="modal-backdrop show"></div>
    <div v-if="showModal" class="modal fade in show modal-active modal-open" id="exportModal">
      <div class="modal-dialog modal-lg">
        <div v-if="updating">
          <div class="loader-container w-100">
            <div class="row justify-content-center">
              <div class="col-md-4 d-flex justify-content-center">
                <div class="loader">Loading...</div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Export lease order history</h4>
            <button type="button" class="close" v-on:click="hideExportModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4">
                <div>From</div>
                <select class="inputfield p-1" style="height: 42px;" v-model="exportStart">
                  <option v-for="date in dateList" :value="date" v-bind:key="date">{{date}}</option>
                </select>
              </div>
              <div class="col-md-4">
                <div>Until</div>
                <select class="inputfield p-1" style="height: 42px;" v-model="exportEnd">
                  <option v-for="date in dateList" :value="date" v-bind:key="date">{{date}}</option>
                </select>
              </div>
              <div class="col-md-4">
                <div>Status</div>
                <select class="inputfield p-1" style="height: 42px;" v-model="exportStatus">
                  <option v-for="leaseStatus in filteredLeaseStatuses" :value="leaseStatus.value" :key="leaseStatus.value">{{ leaseStatus.label }}</option>
                </select>
              </div>
            </div>
          </div><!-- Modal body end -->
          <!-- Modal footer -->
          <div class="modal-footer">
              <div class="col-md-4"><button type="button" class="btn btn-lg btn-outline-primary btn-block" v-on:click="hideExportModal()">Cancel</button></div>
              <div class="col-md-4"><button type="button" class="btn btn-lg btn-primary btn-block" v-on:click="exportLeaseOrders()">Export via email</button></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="modal-backdrop show"></div>
    <!-- Mobile view -->
    <SamAccordion
        v-if="windowWidth < tabletView"
        :class="'.d-none .d-sm-block .d-md-none'"
        :title="`${$store.state.totalLeaseOrderCount > 1 ? 'Leases': 'Lease'} (${$store.state.totalLeaseOrderCount}) `"
        title-payload="filters"
        accordionId="samAccordianTags"
      >
       <v-select
          class="sam-input sam-select mb-4"
          v-model="orderFilter"
          :options="orderList"
          :searchable="true"
          :disabled="!$store.state.leaseOrdersFetched || !$store.state.leaseOrderOptionsFetched"
          placeholder="All order IDs"
        ></v-select>
        <v-select
          class="sam-input sam-select mb-4"
          v-if="isManager"
          v-model="userFilter"
          :options="userList"
          :searchable="true"
          :disabled="!$store.state.leaseOrdersFetched || !$store.state.leaseOrderOptionsFetched"
          placeholder="All users"
        ></v-select>
        <v-select
          class="sam-input sam-select mb-4"
          v-model="addressFilter"
          :options="addressList"
          :searchable="true"
          :disabled="!$store.state.leaseOrdersFetched || !$store.state.leaseOrderOptionsFetched"
          placeholder="All addresses"
        ></v-select>
        <v-select
          class="sam-input sam-select mb-4"
          v-model="itemFilter"
          :options="itemList"
          :searchable="true"
          :disabled="!$store.state.leaseOrdersFetched || !$store.state.leaseOrderOptionsFetched"
          placeholder="All items"
        ></v-select>
        <v-select
          class="sam-input sam-select mb-4"
          v-model="statusFilter"
          label="label"
          :reduce="s => s.value"
          :options="leaseStatuses"
          :searchable="true"
          :disabled="!$store.state.leaseOrdersFetched || !$store.state.leaseOrderOptionsFetched"
          placeholder="All statuses"
        ></v-select>
        <v-select
          class="sam-input sam-select mb-4"
          v-model="noteFilter"
          label="label"
          :reduce="s => s.value"
          :options="noteStatusOptions"
          :searchable="true"
          :disabled="!$store.state.leaseOrdersFetched || !$store.state.leaseOrderOptionsFetched"
          placeholder="All notes"
        ></v-select>
      <SamSecondaryButton @buttonAction="openExportModal()" text="Export as XLS" :disabled="!$store.state.leaseOrdersFetched || !$store.state.leaseOrderOptionsFetched"/>
     </SamAccordion>
     <SamTableRow v-else headerRow>
        <SamTableColumn column6>
          <v-select
            class="sam-input sam-select"
            v-model="orderFilter"
            :options="orderList"
            :searchable="true"
            :disabled="!$store.state.leaseOrdersFetched || !$store.state.leaseOrderOptionsFetched"
            placeholder="All order IDs"
          ></v-select>
        </SamTableColumn>
        <SamTableColumn column6>
          <v-select
            class="sam-input sam-select"
            v-if="isManager"
            v-model="userFilter"
            :options="userList"
            :searchable="true"
            :disabled="!$store.state.leaseOrdersFetched || !$store.state.leaseOrderOptionsFetched"
            placeholder="All users"
          ></v-select>
        </SamTableColumn>
        <SamTableColumn column6>
          <v-select
            class="sam-input sam-select"
            v-model="addressFilter"
            :options="addressList"
            :searchable="true"
            :disabled="!$store.state.leaseOrdersFetched || !$store.state.leaseOrderOptionsFetched"
            placeholder="All addresses"
          ></v-select>
        </SamTableColumn>
        <SamTableColumn column6>
          <v-select
            class="sam-input sam-select"
            v-model="itemFilter"
            :options="itemList"
            :searchable="true"
            :disabled="!$store.state.leaseOrdersFetched || !$store.state.leaseOrderOptionsFetched"
            placeholder="All items"
          ></v-select>
        </SamTableColumn> 
        <SamTableColumn column6>
          <v-select
            class="sam-input sam-select"
            v-model="statusFilter"
            label="label"
            :reduce="s => s.value"
            :options="leaseStatuses"
            :searchable="true"
            :disabled="!$store.state.leaseOrdersFetched || !$store.state.leaseOrderOptionsFetched"
            placeholder="All statuses"
          ></v-select>
        </SamTableColumn>
        <SamTableColumn column6>
          <v-select
            class="sam-input sam-select"
            v-model="noteFilter"
            label="label"
            :reduce="s => s.value"
            :options="noteStatusOptions"
            :searchable="true"
            :disabled="!$store.state.leaseOrdersFetched || !$store.state.leaseOrderOptionsFetched"
            placeholder="All notes"
          ></v-select>
        </SamTableColumn> 
        <div class="sam-counts lease-counts d-none d-md-block col-md-3 d-flex justify-content-center" v-if="$store.state.leaseOrdersFetched">
          <SamParagraph :text="`Showing ${$store.state.totalLeaseOrderCount} ${$store.state.totalLeaseOrderCount === 1 ? 'lease' : 'leases'}.`"></SamParagraph>
        </div>
      </SamTableRow>
    <div v-if="!$store.state.leaseOrdersFetched">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div 
        @click="windowWidth > mobileView ? openEdit(item) : () => {}"
        v-for="item in orders" 
        v-bind:key="item.id"
        >
        <SamTableRow hoverable noSpace clickableRow>
          <SamTableColumn :mobileColumn="windowWidth < mobileView" column6>
            <SamParagraph :text="item.order.order_id" bolded tableItem/>
            <SamParagraph :text="parseDate(item.order.created)" tableItem price/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView" column6>
            <SamParagraph v-if="windowWidth < mobileView" text="User" bolded/>
              <SamParagraph :text="item.order.user_name" tableItem/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView" column6>
            <SamParagraph v-if="windowWidth < mobileView" text="Address" bolded/>
            <SamParagraph :text="item.order.address_name" tableItem/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView" column6>
            <SamParagraph v-if="windowWidth < mobileView" text="Product" bolded/>
            <SamParagraph :text="item.product_name" tableItem/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView" column6>
            <SamParagraph v-if="windowWidth < mobileView" text="Status" bolded/>
            <SamParagraph :text="getLeaseStatusStr(item)" tableItem linked/>
          </SamTableColumn>
          <SamTableColumn :mobileColumn="windowWidth < mobileView" column6>
            <SamParagraph v-if="windowWidth < mobileView" text="Notes" bolded/>
              <div class="row">
                <div class="col-5 pr-0 ml-0 ml-md-1 " :title="item.lease_message">
                    <LeaseItemSquare v-for="state in item.lease_note_states" v-bind:key="item.id + state" v-bind:state="state" />
                </div>
                <div class="col-7"></div>
              </div>
          </SamTableColumn>
          <SamTableColumn v-if="windowWidth < mobileView">
            <SamSecondaryButton @buttonAction="editModal(item.id)" text="Edit"/>
          </SamTableColumn>
          <SamTableColumn v-if="windowWidth < mobileView">
            <SamSecondaryButton @buttonAction="openEdit(item)" text="View" />
          </SamTableColumn>
           <div v-if="(windowWidth >= mobileView) && !item.approval_required" class="sam-small-edit-button sam-lease-button">
              <SamSmallButton @buttonAction="editModal(item.id)" text="Quick edit" noSpace/>
            </div>
        </SamTableRow>
      </div>
      <div class="row" v-if="$store.state.moreLeaseOrdersAvailable">
        <div class="col-5">
        </div>
        <div class="col-2">
          <div class="row justify-content-center">
            <div class="mini-loader">Loading...</div>
          </div>
          <div class="mt-2">
            Loading more results..
          </div>
        </div>
        <div class="col-5">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LeaseItemSquare from './LeaseItemSquare.vue'

import SamAccordion from '../../../components/SamAccordion.vue'
import SamTableColumn from '../../../components/table/SamTableColumn.vue'
import SamTableRow from '../../../components/table/SamTableRow.vue'
import SamParagraph from '../../../components/SamParagraph.vue'
import SamSecondaryButton from '../../../components/SamSecondaryButton.vue'
import SamSmallButton from '../../../components/SamSmallButton.vue'

export default {
  name: 'LeaseOrderListing',
  props: [
    'orders'
  ],
  components: {
    LeaseItemSquare,
    SamAccordion,
    SamTableRow,
    SamTableColumn,
    SamParagraph,
    SamSecondaryButton,
    SamSmallButton
  },
  data(){
    return {
      orderFilter: null,
      userFilter: null,
      addressFilter: null,
      itemFilter: null,
      statusFilter: null,
      noteFilter: null,
      visibleItemCount: 50,
      leaseStatuses: [
        {value: 2, label: "Approval required"},
        {value: 3, label: "Shipped"},
        {value: 5, label: "In use"},
        {value: 7, label: "Returned"},
        {value: 8, label: "Quality control"},
        {value: 9, label: "Out of service"},
        {value: 1, label: "Returned to stock"}
      ],
      showModal: false,
      exportStart: "",
      exportEnd: "",
      exportStatus: 3,
      editLeaseModal: false,
      updating: false,
      editLease: {
        orderId: null,
        leaseOrder: {},
        leaseStatus: null,
        leaseNoteStates: null,
        leaseNote: null,
      },
      hash: "",
      filteredItemCount: null,
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
    }
  },
  created: function () {
    this.setFiltersWithHash(window.location.hash.substring(1));
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted(){
    //Check window width
    window.addEventListener('resize', this.getDimensions);
    // Fetch first 25 lease orders in mount
    if(this.orders.length < 25 && !this.$store.state.leaseOrdersFetched){
      // get orders and then set update to false
      if(this.filterParams){
        this.$store.dispatch('fetchLeaseOrders', {append: false, filters: this.filterParams});
      } else {
        this.$store.dispatch('fetchLeaseOrders', {append: false});
      }
    } else {
      // already got orders, check/set filters
      if(this.$store.state.leaseOrderFilters != this.filterParams){
        // Different filters, fetch orders
        this.$store.dispatch('clearAndFetchLeaseOrders', {append: false, filters: this.filterParams});
      }
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  computed: {
    userList(){
      const userNames = this.$store.state.leaseOrderOptionsFetched ? this.$store.state.leaseOrderFilterOptions.user_names : [];
      // try to sort by last name..
      userNames.sort((a, b) => (a.split(" ").slice(-1)[0].toLowerCase() > b.split(" ").slice(-1)[0].toLowerCase()) ? 1 : -1);
      return [...userNames];
    },
    addressList(){
      const addresses = this.$store.state.leaseOrderOptionsFetched ? this.$store.state.leaseOrderFilterOptions.addresses : [];
      addresses.sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1);
      return [...addresses];
    },
    itemList(){
      const itemNames = this.$store.state.leaseOrderOptionsFetched ? this.$store.state.leaseOrderFilterOptions.lease_product_names : [];
      return itemNames;
    },
    orderList(){
      const orders = this.$store.state.leaseOrderOptionsFetched ? this.$store.state.leaseOrderFilterOptions.order_ids : [];
      return orders;
    },
    isManager(){
      return this.$store.state.membership.group_data.order_history_all_users;
    },
    leaseProductStatusDict(){
      return this.$store.state.leaseOrderOptionsFetched ?this.$store.state.leaseOrderFilterOptions.lease_prod_statuses : {}
    },
    filteredLeaseStatuses() {
      return this.leaseStatuses.filter(status => status.label !== "Returned to stock");
    },
    noteStatuses(){
      if(!this.$store.getters['admin/productOptsFetched']){
        return [];
      }
      return this.$store.getters['admin/productOptions'].lease_note_states;
    },
    noteStatusOptions(){
      return this.noteStatuses.map(ns => ({value: ns[0], label: ns[1]}))
    },
    dateList() {
      const today = new Date();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();
      let startMonth = 1;
      let startYear = 2020;
      let dates = [];
      while (startYear < year || (startMonth <= month && startYear <= year)) {
        dates.push(startMonth + "/" + startYear);
        startMonth += 1;
        if(startMonth > 12){
          startMonth = 1;
          startYear += 1;
        }
      }
      return dates.reverse();
    },
    filterParams(){
      let params = ""
      params = this.orderFilter ? params + "o=" + encodeURIComponent(this.orderFilter) : params;
      params = this.userFilter ? params + "&u=" + encodeURIComponent(this.userFilter) : params;
      params = this.addressFilter ? params + "&a=" + encodeURIComponent(this.addressFilter) : params;
      params = this.itemFilter ? params + "&i=" + encodeURIComponent(this.itemFilter) : params;
      params = this.statusFilter ? params + "&s=" + encodeURIComponent(this.statusFilter) : params;
      params = this.noteFilter ? params + "&n=" + encodeURIComponent(this.noteFilter) : params;
      if(params.startsWith("&")){
        params = params.slice(1);
      }
      return params != "" ? params : null;
    }
  },
  watch: {
    filterParams: function(newVal, oldVal){
      if(newVal != oldVal){
        let hash = window.location.hash.substring(1);
        if(newVal != hash){
          window.location.hash = newVal && newVal.length > 1 ? newVal : "";
          this.hash = newVal;
        }
        // Clear orders from VUEX and fetch first batch from back end
        this.$store.dispatch('clearAndFetchLeaseOrders', {append: false, filters: newVal});
      }
    }
  },
  methods: {
    parseDate(date_str){
      const date = new Date(date_str);
      const month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      return day + '/' + month + '/' + date.getFullYear();
    },
    getLeaseStatus(lp){
      if(lp.back_to_stock){
        return this.leaseStatuses.find(ls => ls.value == 1).label
      }
      const status = this.leaseProductStatusDict[lp.product_id];
      const s = this.leaseStatuses.find(ls => ls.value == status)
      if(s){
        return s.label;
      } else {
        return "";
      }
    },
    getLeaseStatusStr(lp){
      let name = "";
      let date = "";
      if(lp.back_to_stock){
        name = this.getLeaseStatus(lp);
        date = lp.back_to_stock;
      } else {
        const status = this.leaseProductStatusDict[lp.product_id];
        if(status == 1){
          name = this.getLeaseStatus(lp);
          date = lp.back_to_stock;
        }else if(status == 7){
          name = this.getLeaseStatus(lp);
          date = lp.returned;
        }else if(status == 5){
          name = this.getLeaseStatus(lp);
          date = lp.in_use;
        }else if(status == 3){
          name = this.getLeaseStatus(lp);
          date = lp.shipped;
        }else if(status == 8){
          name = this.getLeaseStatus(lp);
          date = lp.quality_control;
        }else if(status == 9){
          name = this.getLeaseStatus(lp);
          date = lp.out_of_service;
        }else if(status == 2){
          name = this.getLeaseStatus(lp);
          date = lp.created;
        }
      }
      const currentDate = new Date(date);
      function pad(n){return n<10 ? '0'+n : n}
      if(currentDate){
        const dateStr = pad(currentDate.getDate()) + "/" + pad(currentDate.getMonth() + 1) + '/' + currentDate.getFullYear();
        return name + "<br /><span class='price'>" + dateStr + "</span>";
      } else {
        return name
      }
    },
    editModal(leaseOrderId){
      const leaseOrder = this.orders.find(lo => lo.id == leaseOrderId);

      this.editLease.orderId = leaseOrder.order.id,
      this.editLease.leaseOrder = leaseOrder;
      this.editLease.leaseStatus = this.leaseProductStatusDict[leaseOrder.product_id];
      this.editLease.leaseNoteStates = leaseOrder.lease_note_states;
      this.editLease.leaseNote = leaseOrder.lease_message;
      this.editLeaseModal = true;
    },
    hideLeaseModal(){
      this.editLeaseModal = false;
      this.editLease = {
        orderId: null,
        leaseOrder: {},
        leaseStatus: null,
        leaseNoteStates: null,
        leaseNote: null,
      }
    },
    updateStatus(){
      this.updating = true;
      this.editLeaseModal = false;
      const vm = this;
      const leaseOrder = this.editLease.leaseOrder;
      const leaseProductStatus = this.leaseProductStatusDict[leaseOrder.product_id];
      let data = {
        lease_message: this.editLease.leaseNote,
        lease_note_states: this.editLease.leaseNoteStates
      };
      if(this.editLease.leaseStatus != leaseProductStatus){
        let statusName = null;
        switch(this.editLease.leaseStatus){
          case 1:
            statusName = "back_to_stock";
            break;
          case 2:
            statusName = "approval_required";
            break;
          case 3:
            statusName = "shipped";
            break;
          case 5:
            statusName = "in_use";
            break;
          case 7:
            statusName = "returned";
            break;
          case 8:
            statusName = "quality_control";
            break;
          case 9:
            statusName = "out_of_service";
            break;
        }
        if(statusName){
          data["update_status"] = statusName;
        }
      }
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + leaseOrder.order.store + '/leaseorders/' + leaseOrder.id + "/";
      let method = 'PATCH';
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
      .then(function (response) {
        //  update lease order to VUEX
        vm.$store.dispatch('updateLeaseOrder', response.data)

        // Check if we need to update product status
        if(vm.editLease.leaseStatus != leaseProductStatus){
          let prodData = {lease_status: vm.editLease.leaseStatus}
          var api_url = process.env.VUE_APP_API_URL + '/api/stores/' + leaseOrder.order.store + '/leaseproducts/' + leaseOrder.product_id + "/";
          vm.$http({
            method: method,
            url: api_url,
            data: prodData,
          })
          .then(function (result) {
            vm.$store.dispatch('updateLeaseProduct', result.data)
            vm.$toastr.s("Your changes have been saved.");
            vm.updating = false;
            document.body.style.cursor='default';
          })
          .catch(function (error) {
            if (error.request){
              vm.$toastr.e(
                error.request.responseText
              );
            }
            vm.$toastr.e(
              error
            );
            document.body.style.cursor='default';
            vm.updating = false;
          });
        } else {
          vm.$toastr.s("Your changes have been saved.");
          vm.updating = false;
          document.body.style.cursor='default';
        }
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        document.body.style.cursor='default';
        vm.updating = false;
      });
    },
    exportLeaseOrders(){
      if(this.dateList.indexOf(this.exportStart) < this.dateList.indexOf(this.exportEnd)){
        this.$toastr.e(
          "'From' has to be before or the same month as 'Until'"
        );
        return;
      }
      const vm = this;
      this.updating = true;
      const store = this.$store.getters.activeStore;
      this.$http({url: process.env.VUE_APP_API_URL + "/api/stores/" + store.id + "/leaseorders/export/", data: {'start': this.exportStart, 'end': this.exportEnd, 'leaseStatus': this.exportStatus}, method: 'POST' })
        .then(function() {
          vm.$toastr.s(
            "Lease order history has been exported as XLS file and will be sent to you via email shortly."
          );
          vm.updating = false;
          vm.hideExportModal();
        })
        .catch(error => {
          vm.updating = false;
          if (error.request){
            vm.$toastr.e(
              error.request.responseText
            );
          }
          vm.$toastr.e(
            error
          );
        })
    },
    openExportModal(){
      // Set defaults
      this.exportStart = this.dateList[0];
      this.exportEnd = this.dateList[0];
      this.showModal = true;
    },
    hideExportModal(){
      this.showModal = false;
    },
    getHashParams(hash){
      var params = {};
      hash.split('&').map(hk => {
        let temp = hk.split('=');
        params[temp[0]] = temp[1];
      });
      return params;
    },
    setFiltersWithHash(hash){
      var params = this.getHashParams(hash)
      window.hashparams = params;
      this.hash = hash;
      this.orderFilter = params['o'] ? decodeURIComponent(params['o']) : null;
      this.userFilter = params['u'] ? decodeURIComponent(params['u']) : null;
      this.addressFilter = params['a'] ? decodeURIComponent(params['a']) : null;
      this.itemFilter = params['i'] ? decodeURIComponent(params['i']) : null;
      this.statusFilter = params['s'] ? Number(decodeURIComponent(params['s'])) : null;
      this.noteFilter = params['n'] ? Number(decodeURIComponent(params['n'])) : null;
    },
    handleScroll(){
      let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= document.documentElement.offsetHeight
      if(bottomOfWindow){
        this.$store.dispatch('fetchLeaseOrders', {append: true});
      }
    },
    getDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    openEdit(item) {
     this.$router.push({ name: 'orders', params: { tab: 'leases', itemId: item.id }}).catch(() => {});
    },
  },
}
</script>

