<template>
  <div>
    <TopNav v-bind:showLogin=false v-bind:showStores=true />
    <SidebarNav />
    <div v-if="updating || !$store.getters.membershipFetched">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <div class="row align-items-end">
        <div class="col-sm-12 col-md-9 mt-md-5">
          <h1 class="mt-4">Order history</h1>
        </div>
        <div class="col-md-3 mb-md-2 d-none d-md-flex justify-content-end">
           <SamSecondaryButton v-if="!this.$route.params.itemId && (this.$route.params.tab == 'order-history' || !this.$route.params.tab )" @buttonAction="openExportModalInOrderListing()" :disabled="!$store.state.ordersFetched || !$store.state.orderOptionsFetched"  text="Export as XLS" noSpace/>
           <SamSecondaryButton v-if="!this.$route.params.itemId && (this.$route.params.tab == 'leases' || !this.$route.params.tab )" @buttonAction="openExportModalInLeaseOrderListing()" :disabled="!$store.state.leaseOrdersFetched || !$store.state.leaseOrderOptionsFetched"  text="Export as XLS" noSpace/>
        </div>
      </div>
      <div v-can="'order_history'" v-if="$store.getters.membershipFetched">
         <SamTabs
          :tabs="tabs"
          :defaultTab="defaultTab"
        />
        <Orders ref="ordersComponent" v-if="ordersTab || defaultTab" />
        <LeaseOrders ref="leaseOrdersComponent" v-can="'management_lease_products'" v-if="leaseOrdersTab" />
        <OrderAnalytics v-can="'order_history'" v-if="analyticsTab" />
        <LeaseOrderAnalytics v-can="'management_lease_products'" v-if="leaseAnalyticsTab" />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import TopNav from '@/modules/general/components/TopNav.vue'
import Orders from './Orders.vue'
import LeaseOrders from './LeaseOrders.vue'
import OrderAnalytics from './OrderAnalytics.vue'
import LeaseOrderAnalytics from './LeaseOrderAnalytics.vue'
import Footer from '@/modules/general/components/Footer.vue'
import SidebarNav from "@/modules/general/components/sidebar/SidebarNav.vue";
import SamTabs from '../../../components/SamTabs.vue'
import SamSecondaryButton from '../../../components/SamSecondaryButton.vue'

export default {
  name: 'OrderManagement',
  components: {
    SidebarNav,
    TopNav,
    Footer,
    Orders,
    LeaseOrders,
    OrderAnalytics,
    LeaseOrderAnalytics,
    SamTabs,
    SamSecondaryButton
  },
  data() {
    return {
       tabs: [
        {
          name: 'Orders',
          access: 'order_history',
          path: 'orders',
          tab: 'order-history'
        },
        {
          name: 'Leases',
          access: 'management_lease_products',
          path: 'orders',
          tab: 'leases'
        },
        {
          name: 'Analytics',
          access: 'order_history',
          path: 'orders',
          tab: 'analytics'
        },
        {
          name: 'Lease analytics',
          access: 'management_lease_products',
          path: 'orders',
          tab: 'lease-analytics'
        },
      ]
    }
  },
  mounted(){
    // Fetch filter options if not fetched
    if(!this.$store.state.orderOptionsFetched){
      this.$store.dispatch('fetchOrderOptions')
    }
    // Fetch productoptions if not fetched
    if(!this.$store.getters['admin/productOptsFetched']){
      this.$store.dispatch('admin/fetchProductOptions')
    }
  },
  watch: {
    canGetOptions: function(newVal, oldVal){
      if(newVal && !oldVal && !this.$store.state.orderOptionsFetched){
        this.$store.dispatch('fetchOrderOptions');
      }
    }
  },
  computed: {
    ordersTab(){
      return this.$route.params.tab == "order-history" || !this.$route.params.tab ? true : false;
    },
    leaseOrdersTab(){
      return this.$route.params.tab == "leases";
    },
    analyticsTab(){
      return this.$route.params.tab == "analytics"
    },
    leaseAnalyticsTab(){
      return this.$route.params.tab == "lease-analytics"
    },
    defaultTab(){
    const noMatch = !this.ordersTab && !this.analyticsTab && !this.leaseOrdersTab && !this.leaseAnalyticsTab;
     return !this.$route.params.tab ? 'order-history' : noMatch;
    },
    updating(){
      return !this.$store.state.orderOptionsFetched;
    },
    canGetOptions(){
      return this.$store.state.activeStore && this.$store.state.activeStore
    }
  },
  methods: {
    openExportModalInOrderListing() {
      this.$refs.ordersComponent.$refs.orderListingComponent.openExportModal()
    },
    openExportModalInLeaseOrderListing() {
      this.$refs.leaseOrdersComponent.$refs.leaseOrderListingComponent.openExportModal()
    },
  },
}
</script>
