<template>
<div class="sam-tabs">
  <div class="row slider-container scroll-container">
    <div class="col-sm-12 p-0 container-scroll">
    <!-- Nav tabs -->
    <ul v-if="noAccess" class="nav nav-tabs d-flex flex-nowrap">
      <div class="slider"></div>
        <li v-for="tab in tabs" :key="tab.id" class="nav-item">
            <router-link 
              :to="{ name: tab.path, params: { tab: tab.tab } }" 
              class="nav-link" 
              active-class="active"
              v-bind:class="{ active: defaultTab === tab.tab }"
              >
                {{tab.name}}
            </router-link>
        </li>
    </ul>
    <ul v-else class="nav nav-tabs d-flex flex-nowrap">
      <div class="slider"></div>
        <li v-for="tab in tabs" :key="tab.id" class="nav-item" v-can="tab.access">
            <router-link 
              :to="{ name: tab.path, params: { tab: tab.tab } }" 
              class="nav-link" 
              active-class="active"
              v-bind:class="{ active: defaultTab === tab.tab }"
              >
                {{tab.name}}
            </router-link>
        </li>
    </ul>
    </div>
  </div>
</div>
</template>

<script>
import 'jquery/dist/jquery.min.js';
import $ from 'jquery';

export default {
  name: 'SamTabs',
  props: {
    tabs: {
      type: Array
    },
    currentTab: {
      type: String
    },
    defaultTab: {},
    noAccess: {
      type: Boolean,
      default: false
    }
  },
  mounted: function() {
    $( document ).ready(function() { 
      setTimeout(() => {
      var actWidth = $(".slider-container .nav-link.active").parent().outerWidth();    
      var actPosition = $(".slider-container .nav-tabs .nav-link.active").position();
      $(".slider-container .slider").css({"left":+ actPosition.left,"width": actWidth});
      }, 100)
        $(".slider-container .nav-tabs .nav-item .nav-link").click(function() {
          var position = $(this).parent().position();
          var width = $(this).parent().outerWidth();

          $(".slider-container .slider").css({"left":+ position.left,"width":width});
        });
      });
    },
}

</script>

<style lang="scss" scoped>

.slider-container {
  margin-left: 0;
  overflow: hidden;
}

.slider-container .nav-tabs{
  position:relative;
  border-bottom: transparent;
}

.slider-container .nav-tabs::after {
  content: '';
  position: absolute;
  border-bottom:1px solid $lightGrey;
  right: 15px;
  left: 0;
  bottom: 0;
}


.slider-container .nav-tabs .nav-item {
  margin:0px;
  margin-right: 16px;
  display: block;
  width: auto;
  white-space: nowrap;
}
.slider-container .nav-tabs .nav-item .nav-link{
  position:relative;
  font-family: $mainFontFamily;
  font-size: $mainFontSize;
  border:none;
  color:$darkGrey;
  text-align: left;
  padding: 8px 0;
  margin-right: 0;
}

.slider-container .nav-tabs .nav-link:hover{
  font-weight: 600;
  color:$almostBlack;
}

.slider-container .slider{
  display:inline-block;
  left: 0;
  height:1px;
  background-color: $almostBlack;
  position:absolute;
  z-index:1;
  bottom:0;
  transition:all .3s ease;
}

.slider-container .nav-tabs .nav-item .active {
  color:$almostBlack;
  font-weight: 600;
}


@media(max-width: 768px) {
  .sam-tabs {
    position: relative;
  }
   .slider-container {
      overflow: scroll;
      overflow-y: hidden;
      margin-left: 0;
      padding-right: 0 !important;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
      width: 100%;
      border-bottom:1px solid $lightGrey;
    }
    .sam-tabs::after {
        content: "";
        display: flex;
        align-items: center;
        justify-content: center;
        height: 99%;
        width: 60px;
        position: absolute;
        padding-right: 15px;
        right: -1px;
        bottom: 0;
        background-image: linear-gradient(85deg, rgba(214, 227, 255, 0) 0%, rgba(255, 255, 255, 1) 61%);
        z-index: 2;
    }

    .slider-container .nav-tabs::after {
      display: none;
    }


    .slider-container .nav-tabs {
      border-bottom: none !important;
    }

    .slider-container .slider {
      bottom: -1px;
    }

    .slider-container .nav-tabs .nav-item:last-child {
      padding-right: 35px;
    }


    ::-webkit-scrollbar {
        -webkit-appearance: none;
        }
        ::-webkit-scrollbar:horizontal {
        height: 0px;
        }
}

</style>
