<template>
  <button v-on:click.stop.prevent="buttonAction()" type="button" class="btn sam-small-button mb-4" :class="{'mb-4': !noSpace}"
          :disabled="disabled === true">{{ text }}
  </button>
</template>

<script>
export default {
  name: 'SamSmallButton',
  components: {},
  props: {
    //! Menu settings
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    noSpace: {
      type: Boolean,
      default: false,
    },
  }, 
  methods: {
     buttonAction(){
      this.$emit('buttonAction');
    },
  }
}
</script>

<style lang="scss" scoped>
.btn.sam-small-button, .btn.sam-small-button:focus {
  background: $lightGrey;
  color: $almostBlack;
  border-color: $lightGrey;
  min-height: $smallButtonHeight;
  font-family: $boldFontStyle;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  padding:4px 6px 3px;
  margin:0 4px 4px 0;
}

.btn.sam-small-button:focus, .btn.sam-small-button:focus-visible, .btn.sam-small-button:active {
  background: $lightGrey;
  color: $almostBlack;
  border-color: $lightGrey;
  min-height: $smallButtonHeight;
  font-family: $boldFontStyle;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
}

.btn.sam-secondary-button:hover {
   opacity: $hoverOpacity;
}

.btn.sam-secondary-button:disabled {
  opacity: $disableOpacity;
}
</style>
