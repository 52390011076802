var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lease-square",class:{
    'lease-default': _vm.state == 1,
    'lease-follow-up': _vm.state == 2,
    'lease-deviation': _vm.state == 3,
    'lease-invoice': _vm.state == 4,
    'lease-comment': _vm.state == 5,
    'lease-unused': _vm.state == 6,
    'lease-not-analysable': _vm.state == 7,
    'lease-damaged': _vm.state == 8,
    'lease-lost': _vm.state == 9
  }})}
var staticRenderFns = []

export { render, staticRenderFns }