<template>
  <div>
    <ViewOrder v-if="showSingleOrder" v-bind:orderData=orderData />
    <OrderListing ref="orderListingComponent" v-else v-bind:orders=orders />
  </div>
</template>

<script>

import OrderListing from '../components/OrderListing.vue'
import ViewOrder from '../components/ViewOrder.vue'

export default {
  name: 'Orders',
  components: {
    OrderListing,
    ViewOrder
  },
  computed: {
    orders(){
      return this.$store.getters['storeOrders'];
    },
    orderData(){
      if(this.$route.params.tab === "view"){
        const orderId = this.$route.params.itemId;
        const order = this.orders.find(o => o.id == orderId);
        return order;
      }
      return {};
    },
    showSingleOrder(){
      return this.$route.params.tab == "view";
    }
  },
}

</script>