<template>
    <span 
      class="sam-tag" 
      :class="{
        'mr-1': spaceRight, 
        'alert-tag': alert, 
        'tableItem': tableItem, 
        'active-tag': active,
        'warning-tag': warning
      }">{{text}}</span>
</template>

<script>

export default {
  name: 'SamTag',
  components: {},
  props: {
    text: {
      type: String,
    },
    spaceRight: {
      type: Boolean,
      default: false
    },
    alert: {
      type: Boolean,
      default: false
    },
    tableItem: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .sam-tag {
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    padding: $tagPadding;
    padding-top: 4px;
    background-color: $tagBackground;
    border-radius: $mainRadius;
    margin-bottom: 0;
    font-family: $boldFontStyle;
     white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    box-sizing: border-box;
  }

  .alert-tag {
    background-color: $alertRed;
    color: $white;
  }

  .active-tag {
    background-color: $activeGreen;
    color: $white;
  }

  .warning-tag {
    background-color: $warningOrange;
    color: $white;
  }

   @media(min-width: 768px) {
    .tableItem {
      margin-left: 7px;
    }
  }
</style>