<template>
  <div>
    <SamAccordion
      v-if="windowWidth < tabletView"
      :class="'.d-none .d-sm-block .d-md-none'"
      title="Filters"
      accordionId="samAccordiananalytics"
    >
      <v-select
        class="sam-input sam-select mb-4"
        v-model="chartAddr"
        label="name"
        :reduce="s => s.id"
        :options="addresses"
        :searchable="true"
        placeholder="All addresses"
        multiple
      ></v-select>
      <v-select
        class="sam-input sam-select mb-4"
        v-model="chartStatuses"
        label="label"
        :reduce="s => s.value"
        :options="leaseStatuses"
        :searchable="true"
        placeholder="All statuses"
        multiple
      ></v-select>
      <v-select
        class="sam-input sam-select mb-4"
        :options="dateList"
        :clearable="false"
        v-model="chartStart"
      ></v-select>
      <v-select
        class="sam-input sam-select mb-4"
        :options="dateList"
        :clearable="false"
        v-model="chartEnd"
      ></v-select>
      <SamPrimaryButton @buttonAction="getChartData()"  text="Update graph" noSpace/>
    </SamAccordion>
    <SamTableRow v-else headerRow>
      <SamTableColumn column6>
        <v-select
          class="sam-input sam-select"
          v-model="chartAddr"
          label="name"
          :reduce="s => s.id"
          :options="addresses"
          :searchable="true"
          placeholder="All addresses"
          multiple
      ></v-select>
      </SamTableColumn>
      <SamTableColumn column6>
        <v-select
          class="sam-input sam-select"
          v-model="chartStatuses"
          label="label"
          :reduce="s => s.value"
          :options="leaseStatuses"
          :searchable="true"
          placeholder="All statuses"
          multiple
      ></v-select>
      </SamTableColumn>
      <SamTableColumn column6>
        <v-select
          class="sam-input sam-select"
          :options="dateList"
          :clearable="false"
          v-model="chartStart"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn column6>
         <v-select
          class="sam-input sam-select"
          :options="dateList"
          :clearable="false"
          v-model="chartEnd"
        ></v-select>
      </SamTableColumn>
        <SamTableColumn spaceColumn></SamTableColumn>
      <SamTableColumn >
        <SamPrimaryButton @buttonAction="getChartData()"  text="Update graph" noSpace/>
      </SamTableColumn>
    </SamTableRow>
    <div class="row">
      <div class="col-lg-7 col-12 mt-4 mb-5">
        <div v-if="updating" style="height: 300px">
          <div class="loader-container">
            <div class="row justify-content-center">
              <div class="col-md-4 d-flex justify-content-center">
                <div class="loader">Loading...</div>
              </div>
            </div>
          </div>
        </div>
        <OrderChart v-else :chartData="chartData" :styles="styles" :options="chartOptions"/>
      </div>
    </div>
  </div>
</template>

<script>

import OrderChart from '../components/OrderChart.vue'
import SamAccordion from '../../../components/SamAccordion.vue'
import SamTableColumn from '../../../components/table/SamTableColumn.vue'
import SamTableRow from '../../../components/table/SamTableRow.vue'
import SamPrimaryButton from '../../../components/SamPrimaryButton.vue'

export default {
  name: 'LeaseOrderAnalytics',
  components: {
    OrderChart,
    SamAccordion,
    SamTableRow,
    SamTableColumn,
    SamPrimaryButton
  },
  data() {
    return {
      updating: true,
      chartStart: null,
      chartEnd: null,
      chartAddr: [],
      chartStatuses: [],
      leaseStatuses: [
        {value: 3, label: "Shipped"},
        {value: 5, label: "In use"},
        {value: 7, label: "Returned"},
        {value: 8, label: "Quality control"},
        {value: 9, label: "Out of service"},
        {value: 1, label: "Returned to stock"}
      ],
      leaseLabels: [],
      leaseDatasets: [],
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
    }
  },
  mounted(){
    this.chartEnd = this.dateList[0];
    this.chartStart = this.dateList[3];
    this.getChartData();

    //Check window width
    window.addEventListener('resize', this.getDimensions);
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  computed: {
    dateList(){
      const today = new Date();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();
      let startMonth = 1;
      let startYear = 2020;
      let dates = [];
      while(startYear < year || (startMonth <= month && startYear <= year)){
        dates.push(startMonth + "/" + startYear);
        startMonth += 1;
        if(startMonth > 12){
          startMonth = 1;
          startYear += 1;
        }
      }
      return dates.reverse();
    },
    chartData(){
      const data = {
        labels: this.leaseLabels,
        datasets: this.leaseDatasets
      }
      return data
    },
    chartOptions(){
      const options =  {
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function(tooltipItems, data) {
              return data.datasets[tooltipItems.datasetIndex].label + ": " + tooltipItems.yLabel;
            }
          }
        },
        scales: {
          xAxes: [{stacked: true}],
          yAxes: [
              {
                id: 'notes',
                position: 'left',
                stacked: true,
                ticks: {
                  beginAtZero: true,
                  precision: 0
                }
              },
              {
                id: 'orders',
                position: 'right',
                gridLines: { drawOnChartArea: false },
                ticks: {
                  beginAtZero: true,
                  precision: 0
                }
              }
            ]
        },
      }
      return options
    },
    addresses(){
      if(!this.$store.getters.addressesFetched){
        this.$store.dispatch('fetchAddresses');
      }
      return this.$store.getters.addresses;
    },
    styles(){
      return {height: '300px'}
    },
    colorizeDict(){
      return {
      1: '#A0D199',
      2: '#50A1CB',
      3: '#F5E690',
      4: '#EAEAEA',
      5: '#9013FE',
      6: '#000000',
      7: '#F5A623',
      8: '#DD8888',
      9: '#944944'
      }
    }
  },
  methods: {
    getDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    colorizeDataset(ds) {
      for(var d of ds){
        d.backgroundColor = d.note_id != '--' ? this.colorizeDict[d.note_id] : '#000000';
        d.borderColor = d.note_id != '--' ? this.colorizeDict[d.note_id] : '#000000';
      }
    },
    getChartData(){
      if(this.dateList.indexOf(this.chartStart) < this.dateList.indexOf(this.chartEnd)){
        this.$toastr.e(
          "Start month has to be before or the same month as end month"
        );
        return;
      }
      this.updating = true;
      const vm = this;
      const store_id = this.$store.getters.activeStore.id;
      let params = {
        'start': this.chartStart,
        'end': this.chartEnd,
        'addresses': this.chartAddr,
        'statuses': this.chartStatuses
      }
      if (params.addresses == []) delete params.address;
      if (params.statuses == []) delete params.product;
      this.$http({
        url: process.env.VUE_APP_API_URL + "/api/stores/" + store_id + "/orders/lease-analytics/",
        method: 'GET',
        params: params,
      })
      .then(response => {
        vm.leaseLabels = response.data.labels;
        vm.leaseDatasets = response.data.datasets;
        vm.colorizeDataset(vm.leaseDatasets);
        vm.updating = false;
      })
      .catch(error => {
        vm.updating = false;
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
      })
    }
  }
}

</script>
