<template>
  <div>
    <div class="modal fade in show modal-active modal-open" id="addUserModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Changing lease status date</h4>
            <button type="button" class="close" v-on:click="closeModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                Are you sure you want to change the '{{getName}}' status date to {{getDateStr}}?
              </div>
            </div>
          </div><!-- Modal body end -->
          <div class="modal-footer mt-2">
            <button type="button" class="btn btn-lg btn-outline-primary mt-2" v-on:click="closeModal()">Cancel</button>
            <button type="button" class="btn btn-lg btn-primary mt-2" v-on:click="updateDate()">Update</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>  
</template>

<script>
export default {
  name: "LeaseDateUpdateModal",
  props: [
    'dateChangeName',
    'dateChangeValue'
  ],
  data(){
    return {
    }
  },
  computed: {
    getDateStr(){
      const dateStr = new Date(this.dateChangeValue)
      var yyyy = dateStr.getFullYear().toString();
      var mm = (dateStr.getMonth()+1).toString();
      var dd  = dateStr.getDate().toString();
      return dd + '.' + mm + '.' + yyyy
    },
    getName(){
      let statusName = null;
      switch(this.dateChangeName){
          case "back_to_stock":
            statusName = "Returned to stock";
            break;
          case "shipped":
            statusName = "Shipped";
            break;
          case "in_use":
            statusName = "In use";
            break;
          case "returned":
            statusName = "Returned";
            break;
        }
      return statusName
    }
  },
  methods: {
    closeModal(){
      this.$emit('closeDateUpdateModal');
    },
    updateDate(){
      this.$emit('saveNewDate');
    }
  }
}
</script>
