<template>
  <div class="mb-4">
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- Modal  -->
      <div v-if="showModal" class="modal fade in show modal-active modal-open" id="leaseModal">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
              <h4 class="modal-title">Change {{ leaseOrder.product_name }} status</h4>
              <button type="button" class="close" v-on:click="hideLeaseModal()">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
              <div class="row">
                <div class="col-md-4">
                  <div>Lease status</div>
                  <div v-if="leaseOrder.back_to_stock" class="form-control price pl-2 pt-2" style="height: 42px;">Returned to stock</div>
                  <v-select
                    v-else
                    :reduce="ls => ls.code"
                    :options="leaseStatuses"
                    :clearable="false"
                    v-model="leaseStatus"
                  ></v-select>
                </div>
                <div class="col-md-4">
                  <div>Lease notes</div>
                  <v-select
                    multiple
                    label="label"
                    :reduce="ns => ns.value"
                    :options="noteStatusOptions"
                    v-model="selectedStates"
                  ></v-select>
                </div>
                <div class="col-md-4">
                  <div>Written lease notes</div>
                  <div><textarea rows=6 v-model="leaseNote" /></div>
                </div>
              </div>
            </div><!-- Modal body end -->
            <!-- Modal footer -->
            <div class="modal-footer">
                <div class="col-md-4"><button type="button" class="btn btn-lg btn-outline-primary btn-block mt-3" v-on:click="hideLeaseModal()">Cancel</button></div>
                <div class="col-md-4"><button type="button" class="btn btn-lg btn-primary btn-block mt-3" v-on:click="updateStatus()">Save changes</button></div>
            </div>
          </div>
        </div>
      </div>
      <LeaseDateUpdateModal v-if="showDateUpdateModal" v-bind:dateChangeName="dateChangeName" v-bind:dateChangeValue="dateChangeValue" @closeDateUpdateModal="closeDateUpdateModal" @saveNewDate="saveNewDate" />
      <div v-if="showModal || showDateUpdateModal" class="modal-backdrop show"></div>
      <div class="row mt-4">
        <div class="col-md-2">Order</div>
        <div class="col-md-2">User</div>
        <div class="col-md-3">Delivery details</div>
        <div class="col-md-5">
          <div class="row">
            <div class="col-md-8">Item</div>
            <div class="col-md-4">Lease status</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr class="mt-2 mb-2" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-6">
              <div>{{leaseOrder.order.order_id}}</div>
              <div class="price">{{parseDate(leaseOrder.order.created)}}</div>
              <div class="mt-3">Cost center</div>
              <div>{{leaseOrder.order.cc_name ? leaseOrder.order.cc_name : ""}}</div>
              <div class="mt-3">Order attachment</div>
              <div><a v-if="leaseOrder.order.attachment" :href="leaseOrder.order.attachment">Link</a><span v-else>None</span></div>
            </div>
            <div class="col-md-6">
              <div>{{leaseOrder.order.user_name}}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mt-3">Lease ID</div>
              <div style="overflow-wrap: anywhere;">{{leaseOrder.lease_id ? leaseOrder.lease_id : ""}}</div>
              <div style="position: absolute; left: -9999px"> <!-- hidden element for link copy -->
                <input id="lease_id_div" type="text" :value="leaseOrder.lease_id ? leaseOrder.lease_id : ''" />
              </div>
              <div v-show="hasLeaseId" class="pointer" @click="copyToClipboard()"><img src="@/assets/images/copy-to-clipboard.png" class="copy-clipboard-img mr-1" alt="Copy">Copy</div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div>{{leaseOrder.order.address_name}}</div>
          <div class="mt-3">{{leaseOrder.order.recipient_name ? leaseOrder.order.recipient_name : leaseOrder.order.user_name}}</div>
          <div v-html="withBR(leaseOrder.order.address_address)"></div>
          <div class="mt-3">Recipient phone</div>
          <div>{{leaseOrder.order.recipient_phone || leaseOrder.order.user_phone}}</div>
          <div class="mt-3" v-if="leaseOrder.order.info">Additional information</div>
          <div v-html="withBR(leaseOrder.order.info)"></div>
        </div>
        <div class="col-md-5">
          <div class="row">
            <div class="col-md-8">
              <div class="row mb-2">
                <div class="col-md-3">
                  <img v-if="!leaseOrder.product_thumb" src="@/assets/images/product-photo-coming-soon-small.jpg" class="product-card-image" alt="">
                  <img v-else width="50" :src="leaseOrder.product_thumb" class="product-card-image" alt="">
                </div>
                <div class="col-md-9">
                  <div>{{leaseOrder.product_name}}</div>
                  <div class="price">SKU: {{leaseOrder.product_ext_id}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-2" v-if="leaseOrder.approval_required">
                <router-link :to="{ name: 'orders', params: { tab: 'view', itemId: leaseOrder.order.id }}">
                  <div>Approval required</div>
                  <div>{{parseDate(leaseOrder.order.created)}}</div>
                </router-link>
              </div>
              <div>Shipped</div>
              <div v-if="!leaseOrder.approval_required"><input type="date" v-model="leaseOrder.shipped" @change="changeDate('shipped', $event)" /></div>
              <div v-else>--</div>
              <div>In use</div>
              <div v-if="origInUse || origReturned || origBackToStock"><input type="date" v-model="leaseOrder.in_use" @change="changeDate('in_use', $event)" /></div>
              <div v-else>--</div>
              <div>Returned</div>
              <div v-if="origReturned || origBackToStock"><input type="date" v-model="leaseOrder.returned" @change="changeDate('returned', $event)" /></div>
              <div v-else>--</div>
              <div>Quality control</div>
              <div v-if="origQualityControl" ><input type="date" v-model="leaseOrder.quality_control" @change="changeDate('quality_control', $event)" /></div>
              <div v-else>--</div>
              <div>Out of service</div>
              <div v-if="origOutOfService" ><input type="date" v-model="leaseOrder.out_of_service" @change="changeDate('out_of_service', $event)" /></div>
              <div v-else>--</div>
              <div>Returned to stock</div>
              <div v-if="origBackToStock" ><input type="date" v-model="leaseOrder.back_to_stock" @change="changeDate('back_to_stock', $event)" /></div>
              <div v-else>--</div>
            </div>
            <div class="col-md-12 mt-2">
              <div style="float: left;" class="mr-1">Lease note:</div>
              <div style="float: left;" v-for="(state, index) in leaseOrder.lease_note_states" v-bind:key="state">
                <div style="float: left;">{{ noteStatusDict[state] }}</div>
                <LeaseItemSquare v-bind:state="state" />
                <div v-if="index != leaseOrder.lease_note_states.length - 1" style="float: left;" class="mr-1 ml-1">,</div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="price" v-if="leaseOrder.lease_message == null || leaseOrder.lease_message == ''">--</div>
              <div v-else v-html="withBR(leaseOrder.lease_message)"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <hr class="mt-2 mb-2" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <router-link :to="{ name: 'orders', params: { tab: 'leases'}, hash: '#' + $route.params.hash }">
            <button class="btn btn-lg btn-outline-primary btn-block mt-2">Back to all leases</button>
          </router-link>
        </div>
        <div class="col-md-3" v-if="!leaseOrder.approval_required">
          <button class="btn btn-lg btn-primary btn-block mt-2"  @click="openLeaseModal()">Change item status</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LeaseDateUpdateModal from './LeaseDateUpdateModal.vue'
import LeaseItemSquare from './LeaseItemSquare.vue'

export default {
  name: 'ViewLeaseOrder',
  components: {
    LeaseDateUpdateModal,
    LeaseItemSquare
  },
  props: [
    'orderData'
  ],
  data() {
    return {
      showModal: false,
      updating: true,
      leaseOrder: null,
      leaseProduct: null,
      leaseStatuses: [
        {code: 3, label: "Shipped"},
        {code: 5, label: "In use"},
        {code: 7, label: "Returned"},
        {code: 8, label: "Quality control"},
        {code: 9, label: "Out of service"},
        {code: 1, label: "Returned to stock"}
      ],
      leaseStatus: null,
      selectedStates: [],
      leaseNote: null,
      showDateUpdateModal: false,
      dateChangeName: null,
      dateChangeValue: null,
      origShipped: null,
      origInUse: null,
      origReturned: null,
      origBackToStock: null,
      origQualityControl: null,
      origOutOfService: null,
    }
  },
  mounted(){
    if(this.orderData && this.orderData.id){
      this.leaseOrder = {...this.orderData};
      this.origShipped = this.leaseOrder.shipped;
      this.origInUse = this.leaseOrder.in_use;
      this.origReturned = this.leaseOrder.returned;
      this.origBackToStock = this.leaseOrder.back_to_stock;
      this.origQualityControl = this.leaseOrder.quality_control;
      this.origOutOfService = this.leaseOrder.out_of_service;
      const leaseProduct = this.$store.getters.leaseproducts.find(lp => lp.id == this.leaseOrder.product_id)
      if(leaseProduct){
        this.leaseProduct = {...leaseProduct};
        this.updating = false;
      } else {
        this.getLeaseProduct(this.leaseOrder.product_id);
      }
    } else {
      this.getLeaseOrder();
    }
  },
  computed: {
    noteStatuses(){
      if(!this.$store.getters['admin/productOptsFetched']){
        return [];
      }
      return this.$store.getters['admin/productOptions'].lease_note_states;
    },
    noteStatusOptions(){
      return this.noteStatuses.map(ns => ({value: ns[0], label: ns[1]}))
    },
    noteStatusDict(){
      return this.noteStatuses.reduce((a,x) => ({...a, [x[0]]: x[1]}), {});
    },
    hasLeaseId(){
      const hideIf = ["", null]
      return !hideIf.includes(this.leaseOrder.lease_id);
    }
  },
  methods: {
    parseDate(date_str){
      const date = new Date(date_str);
      const month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      return day + '/' + month + '/' + date.getFullYear();
    },
    withBR(replace_str){
      return replace_str ? replace_str.replace(/(?:\r\n|\r|\n)/g, '<br />') : "";
    },
    openLeaseModal(){
      this.leaseStatus = this.leaseProduct.lease_status;
      this.selectedStates = this.leaseOrder.lease_note_states;
      this.leaseNote = this.leaseOrder.lease_message;
      this.showModal = true;
    },
    hideLeaseModal(){
      this.showModal = false;
    },
    setOrigValue(name, value){
      // set orig values after updating lease to show datepickers correctly
      switch(name){
        case "shipped":
          this.origShipped = value;
          break;
        case "in_use":
          this.origInUse = value;
          break;
        case "returned":
          this.origReturned = value;
          break;
        case "back_to_stock":
          this.origBackToStock = value;
          break;
        case "quality_control":
          this.origQualityControl = value;
          break;
        case "out_of_service":
          this.origOutOfService = value;
          break;
      }
    },
    updateStatus(){
      this.updating = true;
      this.showModal = false;
      const vm = this;
      let data = {
        lease_message: this.leaseNote,
        lease_note_states: this.selectedStates
      };
      let statusName = null;
      if(this.leaseStatus != this.leaseProduct.lease_status){
        switch(this.leaseStatus){
          case 1:
            statusName = "back_to_stock";
            break;
          case 3:
            statusName = "shipped";
            break;
          case 5:
            statusName = "in_use";
            break;
          case 7:
            statusName = "returned";
            break;
          case 8:
            statusName = "quality_control";
            break;
          case 9:
            statusName = "out_of_service";
            break;
        }
        if(statusName){
          data["update_status"] = statusName;
        }
      }
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.leaseOrder.order.store + '/leaseorders/' + this.leaseOrder.id + "/";
      let method = 'PATCH';
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
      .then(function (response) {
        //  update lease order to VUEX
        vm.$store.dispatch('updateLeaseOrder', response.data)
        let clone = {...response.data};
        delete clone.order;
        vm.leaseOrder = {...vm.leaseOrder, ...clone};
        if(statusName){
          vm.setOrigValue(statusName, response.data[statusName])
        }
        // Check if we need to update product status
        if(vm.leaseStatus != vm.leaseProduct.lease_status){
          let prodData = {lease_status: vm.leaseStatus}
          var api_url = process.env.VUE_APP_API_URL + '/api/stores/' + vm.leaseOrder.order.store + '/leaseproducts/' + vm.leaseProduct.id + "/";
          vm.$http({
            method: method,
            url: api_url,
            data: prodData,
          })
          .then(function (result) {
            vm.$store.dispatch('updateLeaseProduct', result.data)
            vm.$toastr.s("Your changes have been saved.");
            vm.updating = false;
            document.body.style.cursor='default';
          })
          .catch(function (error) {
            if (error.request){
              vm.$toastr.e(
                error.request.responseText
              );
            }
            vm.$toastr.e(
              error
            );
            document.body.style.cursor='default';
            vm.updating = false;
          });
        } else {
          vm.$toastr.s("Your changes have been saved.");
          vm.updating = false;
          document.body.style.cursor='default';
        }
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        document.body.style.cursor='default';
        vm.updating = false;
      });
    },
    changeDate(eka, event){
      this.dateChangeName = eka;
      this.dateChangeValue = event.target.value;
      this.showDateUpdateModal = true;
    },
    closeDateUpdateModal(){
      switch(this.dateChangeName){
        case 'shipped':
          this.leaseOrder.shipped = this.origShipped;
          break;
        case 'in_use':
          this.leaseOrder.in_use = this.origInUse;
          break;
        case 'returned':
          this.leaseOrder.returned = this.origReturned;
          break;
        case 'back_to_stock':
          this.leaseOrder.back_to_stock = this.origBackToStock;
          break;
      }
      this.dateChangeName = null;
      this.dateChangeValue = null;
      this.showDateUpdateModal = false;
    },
    saveNewDate(){
      this.updating = true;
      this.showDateUpdateModal = false;
      let data = {
        update_status: this.dateChangeName,
        new_date: this.dateChangeValue
      }
      const vm = this;
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.leaseOrder.order.store + '/leaseorders/' + this.leaseOrder.id + "/";
      let method = 'PATCH';
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
      .then(function (response) {
        //  update lease order to VUEX
        vm.$store.dispatch('updateLeaseOrder', response.data);
        // don't overwrite order
        delete response.data.order;
        vm.leaseOrder = {...vm.leaseOrder, ...response.data};
        vm.$toastr.s("Your changes have been saved.");
        vm.updating = false;
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        document.body.style.cursor='default';
        vm.updating = false;
      });
    },
    copyToClipboard(){
      // copy lease id to clipboard
      var copyText = document.querySelector("#lease_id_div");
      copyText.select();
      document.execCommand("copy");
      this.$toastr.s("Copied lease id to clipboard");
    },
    getLeaseOrder(){
      const vm = this;
      const leaseId = this.$route.params.itemId;
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.state.activeStore.id + '/leaseorderlist/' + leaseId + "/";
      let method = 'GET';
      this.$http({
        method: method,
        url: api_url,
      })
      .then(function (response) {
        //  update lease order
        vm.leaseOrder = response.data;
        vm.origShipped = response.data.shipped;
        vm.origInUse = response.data.in_use;
        vm.origReturned = response.data.returned;
        vm.origBackToStock = response.data.back_to_stock;
        vm.origQualityControl = response.data.quality_control;
        vm.origOutOfService = response.data.out_of_service;
        const leaseProduct = vm.$store.getters.leaseproducts.find(lp => lp.id == vm.leaseOrder.product_id)
        if(leaseProduct){
          vm.leaseProduct = {...leaseProduct};
          vm.updating = false;
        } else {
          vm.getLeaseProduct(vm.leaseOrder.product_id);
        }
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        vm.updating = false;
      });
    },
    getLeaseProduct(lpId){
      const vm = this;
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.state.activeStore.id + '/leaseproducts/' + lpId + "/";
      let method = 'GET';
      this.$http({
        method: method,
        url: api_url,
      })
      .then(function (response) {
        vm.leaseProduct = response.data;
        vm.updating = false;
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        vm.updating = false;
      });
    }
  }
}
</script>