<template>
  <div>
     <SamAccordion
      v-if="windowWidth < tabletView"
      :class="'.d-none .d-sm-block .d-md-none'"
      title="Filters"
      accordionId="samAccordiananalytics"
    >
      <v-select
        class="sam-input sam-select mb-4"
        :options="dateList"
        :clearable="false"
        v-model="chartStart"
      ></v-select>
      <v-select
        class="sam-input sam-select mb-4"
        :options="dateList"
        :clearable="false"
        v-model="chartEnd"
      ></v-select>
      <v-select
        class="sam-input sam-select mb-4"
        label="name"
        :options="userArray"
        :reduce="u => u.id"
        v-model="chartUser"
        placeholder="All users"
       ></v-select>
       <v-select
         class="sam-input sam-select mb-4"
         label="name"
         :options="countries"
         :reduce="c => c.value"
         v-model="chartDeliveryCountry"
         placeholder="Country"
       ></v-select>
       <v-select
        class="sam-input sam-select mb-4"
        label="name"
        :options="addresses"
        :reduce="a => a.id"
        v-model="chartAddr"
        placeholder="All addresses"
      ></v-select>
       <v-select
        class="sam-input sam-select mb-4"
        label="name"
        :options="productCategories"
        :reduce="c => c.value"
        multiple
        v-model="chartProductCategory"
        placeholder="All categories"
      ></v-select>
       <v-select
        class="sam-input sam-select mb-4"
        label="name"
        :options="productTags"
        :reduce="c => c.value"
        multiple
        v-model="chartProductTag"
        placeholder="All tags"
       ></v-select>
       <v-select
         class="sam-input sam-select mb-4"
         label="name"
         :options="aProducts"
         :reduce="p => p.id"
         multiple
         v-model="chartProduct"
         placeholder="All products"
       ></v-select>
      <v-select
        class="sam-input sam-select mb-4"
        v-model="chartCC"
        label="name"
        :options="costcenters"
        :reduce="cc => cc.id"
        :searchable="true"
        placeholder="All cost centers"
      ></v-select>
      <SamPrimaryButton @buttonAction="getOrderData()"  text="Update graph" noSpace/>
    </SamAccordion>
    <div v-else>
    <SamTableRow headerRow noBorder>
      <SamTableColumn column6>
        <v-select
          class="sam-input sam-select"
          :options="dateList"
          :clearable="false"
          v-model="chartStart"
      ></v-select>
      </SamTableColumn>
      <SamTableColumn column6>
        <v-select
          class="sam-input sam-select"
          :options="dateList"
          :clearable="false"
          v-model="chartEnd"
      ></v-select>
      </SamTableColumn>
      <SamTableColumn column6>
        <v-select
          class="sam-input sam-select"
          label="name"
          :options="userArray"
          :reduce="u => u.id"
          v-model="chartUser"
          placeholder="All users"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn column6>
        <v-select
          class="sam-input sam-select"
          label="name"
          :options="countries"
          :reduce="c => c.value"
          v-model="chartDeliveryCountry"
          placeholder="Country"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn column6>
         <v-select
          class="sam-input sam-select"
          label="name"
          :options="addresses"
          :reduce="a => a.id"
          v-model="chartAddr"
          placeholder="All addresses"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn column6>
         <v-select
          class="sam-input sam-select"
          v-model="chartCC"
          label="name"
          :options="costcenters"
          :reduce="cc => cc.id"
          :searchable="true"
          placeholder="All cost centers"
        ></v-select>
      </SamTableColumn>
    </SamTableRow>
    <SamTableRow headerRowNoSpace>
      <SamTableColumn column6>
        <v-select
          class="sam-input sam-select"
          label="name"
          :options="productCategories"
          :reduce="c => c.value"
          multiple
          v-model="chartProductCategory"
          placeholder="All categories"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn column6>
        <v-select
          class="sam-input sam-select"
          label="name"
          :options="productTags"
          :reduce="c => c.value"
          multiple
          v-model="chartProductTag"
          placeholder="All tags"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn column6>
        <v-select
          class="sam-input sam-select"
          label="name"
          :options="aProducts"
          :reduce="p => p.id"
          multiple
          v-model="chartProduct"
          placeholder="All products"
        ></v-select>
      </SamTableColumn>
      <SamTableColumn column6></SamTableColumn>
      <SamTableColumn spaceColumn></SamTableColumn>
      <SamTableColumn>
        <SamPrimaryButton @buttonAction="getOrderData()"  text="Update graph" noSpace/>
      </SamTableColumn>
    </SamTableRow>
    </div>
    <div class="row">
      <div class="col-lg-7 col-12 mt-4 mb-5">
        <div v-if="updating" style="height: 300px">
          <div class="loader-container">
            <div class="row justify-content-center">
              <div class="col-md-4 d-flex justify-content-center">
                <div class="loader">Loading...</div>
              </div>
            </div>
          </div>
        </div>
        <OrderChart v-else :chartData="chartData" :styles="styles" :options="chartOptions"/>
      </div>
      <div class="col-lg-5 col-12 mt-lg-4 mb-5 align-self-center">
        <div v-if="updating" style="height: 300px">
        </div>
        <div v-else>
          <div><h3>Summary</h3></div>
          <div class="font-weight-bold">Order value</div>
          <div>{{totalValue}}{{$store.getters.currencyStr}}</div>
          <div class="font-weight-bold mt-3">Number of orders</div>
          <div>{{totalOrders}}</div>
          <div class="font-weight-bold mt-3">Number of products</div>
          <div>{{totalProducts}}</div>
          <div class="font-weight-bold mt-3">Estimated Carrier Fee</div>
          <div>{{totalEstCarrierFee}}{{$store.getters.currencyStr}}</div>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-lg-6 col-12">
        <div><h3>Most ordered products (past 12 months)</h3></div>
        <div v-if="updatingMO" style="height: 300px">
          <div class="loader-container">
            <div class="row justify-content-center">
              <div class="col-md-4 d-flex justify-content-center">
                <div class="loader">Loading...</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-for="(p, index) of mostOrdered" v-bind:key="index">
          <div class="col-1">{{index+1}}.</div>
          <div class="col-4 text-truncate pl-0" :title="p.product_name">{{p.product_name}}</div>
          <div class="col-3">{{p.total_count}} pcs</div>
          <div class="col-4">{{p.total_value.toFixed(2)}}{{$store.getters.currencyStr}}</div>
        </div>
        <div v-if="this.mostOrdered.length == 0">No products have been ordered yet</div>
      </div>
      <div class="col-lg-6 col-12">
        <div><h3>Current stock value</h3></div>
        <div v-if="updatingStock" style="height: 300px">
          <div class="loader-container">
            <div class="row justify-content-center">
              <div class="col-md-4 d-flex justify-content-center">
                <div class="loader">Loading...</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!this.stockPcs && !this.stockValue">No products in stock</div>
        <div v-else>Quantity across all stocked products {{this.stockPcs}} pcs worth {{this.stockValue.toFixed(2)}}{{$store.getters.currencyStr}}.</div>
      </div>
    </div>
  </div>
</template>

<script>

import OrderChart from '../components/OrderChart.vue'
import SamAccordion from '../../../components/SamAccordion.vue'
import SamTableColumn from '../../../components/table/SamTableColumn.vue'
import SamTableRow from '../../../components/table/SamTableRow.vue'
import SamPrimaryButton from '../../../components/SamPrimaryButton.vue'

export default {
  name: 'OrderAnalytics',
  components: {
    OrderChart,
    SamAccordion,
    SamTableRow,
    SamTableColumn,
    SamPrimaryButton
  },
  data() {
    return {
      updating: true,
      updatingStock: true,
      updatingMO: true,
      labels: [],
      values: [],
      noOfOrders: [],
      noOfProducts: [],
      valOfCarrOrders: [],
      chartStart: null,
      chartEnd: null,
      chartUser: "",
      chartAddr: "",
      chartProduct: null,
      chartCC: null,
      chartProductCategory: null,
      chartProductTag: null,
      chartDeliveryCountry: null,
      stockPcs: null,
      stockValue: null,
      mostOrdered: [],
      products: [],
      mobileView: 768,
      tabletView: 1044,
      windowWidth: document.documentElement.clientWidth,
    }
  },
  mounted(){
    this.chartEnd = this.dateList[0];
    this.chartStart = this.dateList[3];
    this.getOrderData(true);
    //Check window width
    window.addEventListener('resize', this.getDimensions);
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  computed: {
    dateList(){
      const today = new Date();
      const month = today.getMonth() + 1;
      const year = today.getFullYear();
      let startMonth = 1;
      let startYear = 2020;
      let dates = [];
      while(startYear < year || (startMonth <= month && startYear <= year)){
        dates.push(startMonth + "/" + startYear);
        startMonth += 1;
        if(startMonth > 12){
          startMonth = 1;
          startYear += 1;
        }
      }
      return dates.reverse();
    },
    chartData(){
      const data = {
        labels: this.labels,
        datasets: [
          {
            'label': "Number of orders",
            yAxisID: 'orders',
            data: this.noOfOrders,
            type: 'line',
            backgroundColor: '#000000',
            borderColor: '#000000',
            fill: false,
            lineTension: 0
          },
          {
            label: 'Order value',
            yAxisID: 'orderValues',
            data: this.values,
            backgroundColor: '#9B9B9B',
            borderWidth: 0
          },
        ]
      }
      return data
    },
    chartOptions(){
      const vm = this;
      const options =  {
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function(tooltipItems) { 
              // if order values
              if (tooltipItems.datasetIndex === 1) return 'Value: ' + tooltipItems.yLabel + ' ' + vm.$store.getters.currencyStr;
              // if order count
              else if (tooltipItems.datasetIndex === 0){
                return [tooltipItems.yLabel + ' orders', vm.noOfProducts[tooltipItems.index] + ' products'];
              }
            }
          }
        },
        scales: {
          yAxes: [
            {
              id: 'orderValues',
              position: 'left',
              ticks: {
                beginAtZero: true
              }
            },
            {
              id: 'orders',
              position: 'right',
              gridLines: { drawOnChartArea: false },
              ticks: {
                beginAtZero: true,
              }
            }
          ]
        }
      }
      return options
    },
    styles(){
      return {height: '300px'}
    },
    addresses(){
      if(!this.$store.getters.addressesFetched){
        this.$store.dispatch('fetchAddresses');
      }
      return this.$store.getters.addresses;
    },
    profiles(){
      if(!this.$store.getters['admin/profilesFetched']){
        this.$store.dispatch('admin/fetchMembers')
      }
      return this.$store.getters['admin/storeProfiles'];
    },
    userArray(){
      return this.profiles.map(p => ({name: p.user.first_name + " " + p.user.last_name, id: p.user.id}))
    },
    aProducts(){
      if (this.products.length === 0) {
        // TODO: must be replaced by store after the new v-select component is ready.
        this.getProducts();
      }
      return this.products;
    },
    totalValue(){
      return this.values.reduce((sum, x) => sum + x).toFixed(2);
    },
    totalOrders(){
      return this.noOfOrders.reduce((sum, x) => sum + x);
    },
    totalProducts(){
      return this.noOfProducts.reduce((sum, x) => sum + x);
    },
    totalEstCarrierFee(){
      return this.valOfCarrOrders.reduce((sum, x) => sum + x).toFixed(2);
    },
    costcenters () {
      if(this.$store.getters.costcenters.length < 1 && !this.$store.getters.ccsFetched){
        this.$store.dispatch('fetchCostCenters');
        return []
      }
      if(this.$store.getters.isStaff){
        // return all ccs to staff
        return this.$store.getters.costcenters;
      }
      if(!this.$store.getters.membership.costcenters){
        return []
      }
      return this.$store.getters.costcenters.filter(cc => this.$store.getters.membership.costcenters.includes(cc.id));
    },
    productOptions() {
      if(!this.$store.getters['admin/productOptsFetched']){
        this.$store.dispatch('admin/fetchProductOptions')
      }
      return this.$store.getters['admin/productOptions'];
    },
    productCategories() {
      if(!this.productOptions.categories){
        return [];
      }
      return this.productOptions.categories.map(c => {
        let obj = {}
        obj['name'] = c[1]
        obj['value'] =  c[0]
        return obj
      })
    },
    productTags() {
      if(this.$store.getters.productTags.length < 1 ){
        this.$store.dispatch('fetchTags');
        return []
      }
      const filteredTags = this.$store.getters.productTags.filter(tagItem => {
      return tagItem.products.some(productId => this.products.some(product => product.id === productId));
      });
      return filteredTags.map(c => {
        let obj = {}
        obj['name'] = c['name']
        obj['value'] =  c['id']
        return obj
      });
    },
    countries() {
      if (!this.$store.getters['admin/countriesFetched']) {
        this.$store.dispatch('admin/fetchCountries')
      }
      const countries = this.$store.getters['admin/countries'].map(c => ({name: c[1], value: c[0]}))
      // remove duplicates
      return countries.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);
    },
  },
  methods: {
     getDimensions() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    getOrderData(mounted=false){
      if(this.dateList.indexOf(this.chartStart) < this.dateList.indexOf(this.chartEnd)){
        this.$toastr.e(
          "Start month has to be before or the same month as end month"
        );
        return;
      }
      this.updating = true;
      const vm = this;
      const store_id = this.$store.getters.activeStore.id;
      let params = {
        'start': this.chartStart,
        'end': this.chartEnd,
        'user': this.chartUser,
        'address': this.chartAddr,
        'product': this.chartProduct,
        'cc': this.chartCC,
        'category': this.chartProductCategory,
        'tag': this.chartProductTag,
        'country': this.chartDeliveryCountry,
      }
      if (params.user == null || params.user === "") delete params.user;
      if (params.address == null || params.address === "") delete params.address;
      if (params.product == null || params.product.length === 0) delete params.product;
      if (params.cc == null) delete params.cc;
      if (params.category == null || params.category.length === 0) delete params.category;
      if (params.tag == null || params.tag.length === 0) delete params.tag;
      if (params.country == null) delete params.country;

      this.$http({
        url: process.env.VUE_APP_API_URL + "/api/stores/" + store_id + "/orders/analytics/",
        method: 'GET',
        params: params,
      })
      .then(response => {
        vm.labels = response.data.labels;
        vm.noOfOrders = response.data.orders;
        vm.values = response.data.values;
        vm.noOfProducts = response.data.prod_count;
        vm.valOfCarrOrders = response.data.estimated_carrier_fee;
        vm.updating = false;
        if(mounted){
          this.getStockValue();
        }
      })
      .catch(error => {
        vm.updating = false;
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
      })
    },
    getStockValue(){
      const vm = this;
      const store_id = this.$store.getters.activeStore.id;
      this.$http({
        url: process.env.VUE_APP_API_URL + "/api/stores/" + store_id + "/orders/analytics/stockvalue/",
        method: 'GET'
      })
      .then(response => {
        vm.stockPcs = response.data.products;
        vm.stockValue = response.data.value;
        vm.updatingStock = false;
        vm.getMostOrdered();
      })
      .catch(error => {
        vm.updatingStock = false;
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
      })
    },
    getMostOrdered(){
      const vm = this;
      const store_id = this.$store.getters.activeStore.id;
      this.$http({
        url: process.env.VUE_APP_API_URL + "/api/stores/" + store_id + "/orders/analytics/mostordered/",
        method: 'GET'
      })
      .then(response => {
        vm.mostOrdered = response.data;
        vm.updatingMO = false;
      })
      .catch(error => {
        vm.updatingMO = false;
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
      })
    },
    getProducts() {
      const vm = this
      const storeId = this.$store.getters.activeStore.id
      this.$http({
        url: process.env.VUE_APP_API_URL + "/api/stores/" + storeId + "/products/?page_size=250",
        method: 'GET'
      }).then(response => {
        const products = response.data.results ? response.data.results : []
        vm.products = products.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        })
      }).catch(error => {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        } else {
          vm.$toastr.e(
          error
        );
        }
      })
    }
  }
}

</script>
