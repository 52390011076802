<template>
  <div>
    <div class="modal fade in show modal-active modal-open" id="exportProductsModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div v-if="!membersFetched || !productsFetched" style="height: 300px">
            <div class="loader-container">
              <div class="row justify-content-center">
                <div class="col-md-4 d-flex justify-content-center">
                  <div class="loader">Loading...</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="modal-header">
              <h4 class="modal-title" v-if="approvalAllowed">Approve order from {{order.user_name}}</h4>
              <h4 class="modal-title" v-else>This order can't be approved!</h4>
              <button type="button" class="close" v-on:click="closeModal()">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
              <div v-if="showDeclineMessage">
                <textarea rows=8 v-model="message" />
              </div>
              <div v-else>
                <div v-if="approvalAllowed">
                  <div class="row mb-3">
                    <div class="col-12">
                      Order was placed on {{orderDate}} with the following items:
                    </div>
                  </div>
                  <div class="row mb-3" v-for="(p, index) in order.products" v-bind:key="index">
                    <div class="col-2">
                      <img v-if="!p.product_thumb" src="@/assets/images/product-photo-coming-soon-small.jpg" class="product-card-image" alt="">
                      <img v-else width="50" :src="p.product_thumb" class="product-card-image" alt="">
                    </div>
                    <div class="col-10">
                      <div>{{p.product_name}}<span class="ml-1" v-if="p.variant_name">- {{p.variant_name}}</span></div>
                      <div class="price">{{p.product_price}}{{$store.getters.currencyStr}} x {{p.quantity}}</div>
                      <div v-if="p.stock_management && !prodInStock(p.product_id, p.variant_id, p.quantity)" class="form-field-error position-static">Not enough items in stock ({{stockCount(p.product_id, p.variant_id)}} in stock)</div>
                    </div>
                  </div>
                  <div class="row mb-3" v-for="(lp, lpIndex) in order.lease_products" v-bind:key="'lp'+lpIndex">
                    <div class="col-2">
                      <img v-if="!lp.product_thumb" src="@/assets/images/product-photo-coming-soon-small.jpg" class="product-card-image" alt="">
                      <img v-else width="50" :src="lp.product_thumb" class="product-card-image" alt="">
                    </div>
                    <div class="col-10">
                      <div>{{lp.product_name}}</div>
                      <div class="price">x 1</div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-2"></div>
                    <div class="col-10">
                      <div class="row">
                        <div class="col-2">
                          <div>Total</div>
                          <div>VAT</div>
                        </div>
                        <div class="col-10">
                          <div>12345€</div>
                          <div>0.00€</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else  v-html="notAllowedReasonTxt"></div>
              </div>
            </div><!-- Modal body end -->
            <div class="modal-footer mt-2" v-if="showDeclineMessage">
              <div class="col-md pl-0 pr-0 pr-md-2">
                <button type="button" class="btn btn-lg btn-primary mt-2 w-100" v-on:click="sendDecline()">Send reply and decline order</button>
              </div>
              <div class="col-md px-0 px-md-2">
                <button type="button" class="btn btn-lg btn-outline-primary mt-2 w-100" v-on:click="closeModal()">Cancel</button>
              </div>
            </div>
            <div class="modal-footer mt-2" v-else>
              <div class="col-md pl-0 pr-0 pr-md-2">
                <button type="button" v-if="anyOutOfStock || !approvalAllowed" class="btn btn-lg btn-primary mt-2 w-100" disabled>Approve order</button>
                <button type="button" v-else class="btn btn-lg btn-primary mt-2 w-100" v-on:click="approveOrder()">Approve order</button>
              </div>
              <div class="col-md px-0 px-md-2">
                <button type="button" class="btn btn-lg btn-outline-primary mt-2 w-100" v-on:click="showDecline()">Reply and decline</button>
              </div>
              <div class="col-md pl-0 pl-md-2 pr-0">
                <button type="button" class="btn btn-lg btn-outline-primary mt-2 w-100" v-on:click="closeModal()">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show"></div>
  </div>  
</template>

<script>
export default {
  name: "OrderApprovalModal",
  props: [
    "order",
    "orderDate"
  ],
  data(){
    return {
      showDeclineMessage: false,
      defaultMessage: "Unfortunately, we weren’t able to approve your order this time. Please contact me if you have any questions.\n\nWe apologise for any inconvenience.\nBest regards,\n",
      message: "",
      productsFetched: false,
      productObjects: [],
      leaseProductObjects: []
    }
  },
  mounted(){
    this.fetchProducts();
    const user = this.$store.state.profile.user;
    const name = user.first_name + " " + user.last_name;
    this.message = this.defaultMessage + name;
  },
  computed: {
    stockDict(){
      let stockProds = {}
      for(let p of this.order.products){
        if(p.stock_management){
          const inStock = this.prodInStock(p.product_id, p.variant_id, p.quantity);
          if(p.product_id in stockProds){
            stockProds[p.product_id][p.variant_id] = inStock
          } else {
            let newVar = {}
            newVar[p.variant_id] = inStock;
            stockProds[p.product_id] = newVar;
          }
        }
      }
      return stockProds;
    },
    anyOutOfStock(){
      for(const key in this.stockDict){
        for(const key2 in this.stockDict[key]){
          // check if variant not in stock
          if(!this.stockDict[key][key2]){
            // return true if out of stock
            return true;
          }
        }
      }
      return false;
    },
    membersFetched(){
      if(!this.$store.getters['admin/membersFetched']){
        this.$store.dispatch('admin/fetchMembers');
      }
      return this.$store.getters['admin/membersFetched']
    },
    orderMember(){
      const member = this.$store.getters['admin/storeMembers'].find(mem => mem.user == this.order.user_id)
      if(member){
        return member;
      }
      return {};
    },
    noPermissionProducts(){
      // get all order product ids
      const orderProdIds = this.order.products.map(p => p.product_id);
      // get real products with those ids
      const prods = this.productObjects.filter(p => orderProdIds.includes(p.id))
      // exclude prods with no groups
      const prodsWithGroup = prods.filter(p => p.groups.length > 0)
      // exclude prods with users group if not admins
      let prodsNoRight = []
      if(!this.orderMember.group_data.admin_group){
        let userGroup = this.orderMember.group;
        prodsNoRight= prodsWithGroup.filter(p => !p.groups.includes(userGroup))
      }
      return prodsNoRight;
    },
    notAvailableProducts(){
      let notAvailable = []
      // ids of currently published products
      const pubProdIds = this.productObjects.filter(p => p.status == 2).map(p => p.id)
      // order products that are not in published array
      const notPubProds = this.order.products.filter(p => !pubProdIds.includes(p.product_id))
      notAvailable = [...notPubProds]

      // order products with variants
      const prodsWithVariant = this.order.products.filter(p => p.variant_id != null)
      for(const p of prodsWithVariant){
        // find the product and check if the variant still exists
        const product = this.productObjects.find(prod => prod.id == p.product_id)
        if(product && !product.variants.find(v => v.id == p.variant_id)){
          // didnt find variant, add to notAvail
          notAvailable = [...notAvailable, p]
        }
      }

      // ids of currently published lease products
      const pubLeaseProdIds = this.leaseProductObjects.filter(p => p.status == 2).map(p => p.id)
      // order products that are not in published array
      const notPubLeaseProds = this.order.lease_products.filter(lp => !pubLeaseProdIds.includes(lp.product_id))

      notAvailable = [...notAvailable, ...notPubLeaseProds]
      return notAvailable;
    },
    approvalAllowed(){
      return this.noPermissionProducts.length == 0 && this.notAvailableProducts.length == 0;
    },
    notAllowedReasonTxt(){
      let reason = "";
      if(this.notAvailableProducts.length > 0){
        const pNames = this.notAvailableProducts.map(function(p) {
          if(p.variant_name != null){
            return p.product_name + " - " + p.variant_name
          } else {
            return p.product_name
          }
        }).join(', ')
        reason = "Unfortunately, this order can’t be approved, because " + pNames + " " + (this.notAvailableProducts.length > 1 ? "are" : "is") + " not published or its variants have been removed."
      }
      if(this.noPermissionProducts.length > 0){
        const pNames = this.noPermissionProducts.map(p => p.name).join(', ');
        reason += (reason.length > 0 ? "<br /><br />" : "") + "Unfortunately, this order can't be approved, because the user is not in a user group anymore that have permission to order these items " + pNames + "."
      }
      return reason;
    }
  },
  methods: {
    fetchProducts(){
      if(this.$store.state.activeStore && !this.productsFetched){
        const ids = this.order.products.map(p => p.product_id);
        const vm = this;
        const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.state.activeStore.id + '/products/';
        this.$http({
          url: api_url, method: 'GET', params: {id: ids}
        })
        .then(resp => {
          vm.productObjects = resp.data.results;
          if(vm.order.lease_products && vm.order.lease_products.length > 0){
            vm.fetchLeaseProducts();
          } else {
            vm.productsFetched = true;
          }
        })
        .catch(function() {

        })
      }
    },
    fetchLeaseProducts(){
      if(this.$store.state.activeStore && !this.productsFetched){
        const ids = this.order.lease_products.map(p => p.product_id);
        const vm = this;
        const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.state.activeStore.id + '/leaseproducts/';
        this.$http({
          url: api_url, method: 'GET', params: {id: ids}
        })
        .then(resp => {
          vm.leaseProductObjects = resp.data.results;
          vm.productsFetched = true;
        })
        .catch(function() {

        })
      }
    },
    closeModal(){
      this.showDeclineMessage = false;
      this.$emit('closeApprovalModal');
    },
    showDecline(){
      this.showDeclineMessage = true;
    },
    sendDecline(){
      this.$emit('orderApproval', {approved: false, message: this.message});
    },
    approveOrder(){
      this.$emit('orderApproval', {approved: true, message: ""});
    },
    prodInStock(productId, variantId, ordCount){
      const prod = this.productObjects.find(p => p.id == productId);
      if(prod){
        const variant = prod.variants.find(v => v.id == variantId);
        if(variant && ordCount > variant.stock_quantity){
          return false;
        }
      }
      return true;
    },
    stockCount(pId, vId){
      const prod = this.productObjects.find(p => p.id == pId);
      if(prod){
        const variant = prod.variants.find(v => v.id == vId);
        return variant.stock_quantity;
      }
      return 0
    }
  }
}
</script>
