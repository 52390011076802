<template>
  <div>
    <ViewLeaseOrder v-if="showSingleOrder" v-bind:orderData=orderData />
    <LeaseOrderListing ref="leaseOrderListingComponent" v-else v-bind:orders=orders />
  </div>
</template>

<script>

import LeaseOrderListing from '../components/LeaseOrderListing.vue'
import ViewLeaseOrder from '../components/ViewLeaseOrder.vue'

export default {
  name: 'LeaseOrders',
  components: {
    LeaseOrderListing,
    ViewLeaseOrder
  },
  data() {
    return {
      updating: true
    }
  },
  mounted(){
    // Fetch filter options for lease orders if not fetched
    if(!this.$store.state.leaseOrderOptionsFetched){
      this.$store.dispatch('fetchLeaseOrderOptions')
    }
  },
  watch: {
    canGetOptions: function(newVal, oldVal){
      if(newVal && !oldVal && !this.$store.state.orderOptionsFetched){
        this.$store.dispatch('fetchLeaseOrderOptions');
      }
    }
  },
  computed: {
    orders(){
      return this.$store.state.storeLeaseOrders;
    },
    orderData(){
      if(this.$route.params.tab == "leases" && this.$route.params.itemId != null){
        const leaseId = this.$route.params.itemId;
        const order = this.orders.find(o => o.id == leaseId);
        return order;
      }
      return {};
    },
    showSingleOrder(){
      return this.$route.params.tab == "leases" && this.$route.params.itemId != null;
    },
    canGetOptions(){
      return this.$store.state.activeStore && this.$store.state.activeStore
    }
  },
}

</script>