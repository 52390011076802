<template>
  <div 
    class="lease-square" 
    :class="{
      'lease-default': state == 1,
      'lease-follow-up': state == 2,
      'lease-deviation': state == 3,
      'lease-invoice': state == 4,
      'lease-comment': state == 5,
      'lease-unused': state == 6,
      'lease-not-analysable': state == 7,
      'lease-damaged': state == 8,
      'lease-lost': state == 9
    }"
  >&nbsp;</div>
</template>

<script>
export default {
  name: 'LeaseItemSquare',
  props: [
    'state',
  ],
}
</script>
