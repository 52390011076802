<template>
  <div class="mb-4">
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <OrderApprovalModal v-if="showApprovalModal"
                          v-bind:order=order
                          v-bind:orderDate=parseDate(order.created)
                          @closeApprovalModal="closeApprovalModal()"
                          @orderApproval="orderApproval"
      />
      <!--   Order details   -->
      <div class="row mt-4">
        <div class="col-12">
          <h2>Order {{ order.order_id }}</h2>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6 col-md-3">
          <div>
            <div class="font-weight-bold">Order date</div>
            <div>{{ parseDate(order.created) }}</div>
          </div>
          <div class="mt-4">
            <div class="font-weight-bold">Order attachment</div>
            <div v-if="order.attachment">
              <a :href="order.attachment" class="font-weight-bold text-underline"
                 target="_blank">{{ getFileName(order.attachment) }}</a>
            </div>
            <div v-else>None</div>
          </div>
          <div class="mt-4">
            <div class="font-weight-bold">Order tracking link</div>
            <div v-if="order.is_approved">
              <div v-if="storeOrderCarriers.length !== 0">
                <div v-for="(soc, idx) of storeOrderCarriers" :key="idx">
                  <span v-if="soc.carrier_label">{{ carrierName(soc.carrier_label) }}&nbsp;</span>
                  <a :href="soc.tracking_link" class="" target="_blank">{{ soc.tracking_number }}</a>
                  <span class="text-important float-right" :class="soc.status === 6 ? 'tag-green' : 'tag-yellow'">{{
                      storeOrderCarrierStatuses[soc.status]
                    }}</span>
                </div>
              </div>
              <div v-else>
                N/A
              </div>
            </div>
            <div v-else>
              N/A
            </div>
          </div>
          <div class="mt-4">
            <div class="font-weight-bold">Rebate</div>
            <div v-if="order.rebate">
              {{ order.rebate }}
            </div>
            <div v-else>N/A</div>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div>
            <div class="font-weight-bold">User or campaign creator</div>
            <div>{{ order.user_name }}</div>
          </div>
          <div class="mt-4">
            <div class="font-weight-bold">Campaign name</div>
            <div v-if="order.campaign_name">{{ order.campaign_name }}</div>
            <div v-else>N/A</div>
          </div>
          <div class="mt-4">
            <div class="font-weight-bold">Cost center</div>
            <div v-if="order.cc_name">{{ order.cc_name }}</div>
            <div v-else>N/A</div>
          </div>
          <div v-if="order.requires_approval || order.campaign_invoice" class="mt-4">
            <div class="font-weight-bold">Order status</div>
            <div v-if="order.requires_approval">
              <span v-if="order.approval_time"
                    :class="{ 'tag-green': order.is_approved, 'tag-red': !order.is_approved }">{{
                  order.is_approved ? "Approved" : "Declined"
                }}</span>
              <div v-else>
                <span v-if="$store.getters.isAdmin ||this.$store.state.membership.group_data['campaign_create']" class="text-important tag-yellow pointer"
                      v-on:click="showApprovalModal = true">Approval needed</span>
                <span v-else class="text-important tag-yellow">Approval needed</span>
              </div>
            </div>
            <div v-if="order.campaign_invoice">
              <span v-if="order.invoice_paid_time == null" class="text-important tag-yellow">Invoice required</span>
              <span v-else class="text-important tag-green">Invoiced</span>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <div class="mt-4 mt-md-0">
            <div class="font-weight-bold">Recipient info</div>
            <div>{{ order.recipient_name }}</div>
            <div>{{ order.recipient_phone }}</div>
            <div>{{ order.recipient_email }}</div>
          </div>
          <div class="mt-4">
            <div class="font-weight-bold">Delivery details</div>
            <div>{{ order.address_name }}</div>
            <div>{{ order.recipient_name }}</div>
            <div>{{ order.address_address }}</div>
            <div>{{ order.address_country }}</div>
          </div>
        </div>
        <div class="col-6 col-md-3 mt-4 mt-md-0">
          <div>
            <div class="font-weight-bold">Delivery note</div>
            <div v-if="order.info">{{ order.info }}</div>
            <div v-else>N/A</div>
          </div>
          <div v-if="order.recipient_email" class="mt-4">
            <div class="font-weight-bold">Order tracking link email</div>
            <div>{{ order.campaign_id == null ? order.user_email : order.recipient_email }}</div>
          </div>
          <div v-if="isCompanyInvoiceDetails" class="mt-4">
            <div class="font-weight-bold">Invoicing details</div>
            <div>{{ order.company_name }}</div>
            <div>{{ order.company_vat_number }}</div>
            <div>{{ order.company_invoice_email }}</div>
          </div>
        </div>
      </div>
      <div class="d-none d-md-flex row my-4">
        <div class="col-md-3">
          <div class="font-weight-bold class=font-weight-bold">Product name</div>
        </div>
        <div class="col-md-3">
          <div class="font-weight-bold class=font-weight-bold">SKU and print file</div>
        </div>
        <div class="col-md-3">
          <div class="font-weight-bold class=font-weight-bold">Price x quantity</div>
        </div>
        <div class="col-md-3">
          <div class="font-weight-bold class=font-weight-bold">Subtotal</div>
        </div>
      </div>
      <div class="row my-4 d-md-none">
        <div class="col-12">
          <div class="font-weight-bold class=font-weight-bold">Products in this order</div>
        </div>
      </div>
      <!--   Product list   -->
      <div v-for="(p, index) in order.products" v-bind:key="index">
        <!--    Desktop    -->
        <div class="row d-none d-md-flex">
          <div class="col-md-3">
            <div class="d-flex">
              <div class="d-inline-block mr-2 rounded-sm overflow-hidden" style="max-width: 48px;">
                <img v-if="p.product_thumb" :src="p.product_thumb" class="img-width" alt="">
                <img v-else src="@/assets/images/product-photo-coming-soon-small.jpg" class="img-width" alt="">
              </div>
              <div class="d-inline-block">
                <div class="font-weight-bold">{{ p.product_name }}</div>
                <div v-if="p.variant_name" class="text-gray">{{ p.variant_name }}</div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div>{{ getSKU(p.product_ext_id, p.variant_sku) }}</div>
            <div v-if="p.print_file_url">
              <a :href="p.print_file_url" class="font-weight-bold text-gray text-underline"
                 target="_blank">{{ getFileName(p.print_file_url) }}</a>
            </div>
            <div v-else class="text-gray">N/A</div>
          </div>
          <div class="col-md-3">
            <div>{{ p.product_price }} {{ $store.getters.currencyStr }} x {{ p.quantity }}</div>
          </div>
          <div class="col-md-3">
            <div>{{ (p.product_price * p.quantity).toFixed(2) }} {{ $store.getters.currencyStr }}</div>
          </div>
        </div>
        <!--    Mobile    -->
        <div class="row mt-2 d-md-none">
          <div class="col-6 d-flex flex-column">
            <div class="d-flex justify-content-center align-items-center overflow-hidden w-100 h-auto"
                 style="max-width: 170px;">
              <img v-if="p.product_thumb" :src="p.product_thumb" class="img-width rounded-sm" alt="">
              <img v-else src="@/assets/images/product-photo-coming-soon-small.jpg" class="img-width rounded-sm" alt="">
            </div>
          </div>
          <div class="col-6 d-flex flex-column">
            <div>
              <div class="font-weight-bold">{{ p.product_name }}</div>
              <div v-if="p.variant_name">{{ p.variant_name }}</div>
              <div class="text-gray">SKU: {{ getSKU(p.product_ext_id, p.variant_sku) }}</div>
            </div>
            <div v-if="p.print_file_url" class="mt-4">
              <div class="font-weight-bold">Print file</div>
              <a :href="p.print_file_url" class="font-weight-bold text-gray text-underline"
                 target="_blank">{{ getFileName(p.print_file_url) }}</a>
            </div>
            <div class="mt-4">
              <div class="font-weight-bold">Price x quantity</div>
              <div>{{ p.product_price }} {{ $store.getters.currencyStr }} per item</div>
              <div v-if="p.quantity">x {{ p.quantity }}</div>
            </div>
            <div class="mt-4">
              <div class="font-weight-bold">Subtotal</div>
              <div>{{ (p.product_price * p.quantity).toFixed(2) }} {{ $store.getters.currencyStr }}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <hr class="mt-3 mb-3"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="offset-6 col-6 offset-md-6 col-md-3 ">
          <div class="font-weight-bold">Delivery fee</div>
          <div>{{ deliveryFee }} {{ $store.getters.currencyStr }}</div>
        </div>
        <div class="offset-6 col-6 mt-4 offset-md-0 col-md-3 mt-md-0">
          <div class="font-weight-bold">Total</div>
          <div>{{ order.order_total }} {{ $store.getters.currencyStr }}</div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-3">
          <router-link :to="{ name: 'orders', hash: '#' + $route.params.hash}">
            <button class="btn btn-lg btn-outline-primary btn-block mt-2">Back to all orders</button>
          </router-link>
        </div>
        <div class="col-md-3">
          <button class="btn btn-lg btn-outline-primary btn-block mt-2" v-on:click="copyProducts()">Copy products to
            cart
          </button>
        </div>
        <div class="col-md-3">
          <button class="btn btn-lg btn-outline-primary btn-block mt-2" v-on:click="openDeliveryNote()">Print delivery
            note
          </button>
        </div>
        <div class="col-md-3">
          <button class="btn btn-lg btn-outline-primary btn-block mt-2" v-on:click="openInvoice()">Create invoice
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import OrderApprovalModal from './OrderApprovalModal.vue'

export default {
  name: 'ViewOrder',
  props: [
    'orderData'
  ],
  components: {
    OrderApprovalModal
  },
  data() {
    return {
      showApprovalModal: false,
      updating: true,
      order: null,
      carriersInfo: null
    }
  },
  mounted() {
    if (this.orderData && this.orderData.id) {
      this.order = {...this.orderData};
      this.updating = false;
    } else {
      this.getOrder();
    }
  },
  computed: {
    isCompanyInvoiceDetails() {
      return this.order.company_name && this.order.company_vat_number && this.order.company_invoice_email
    },
    deliveryFee() {
      return this.order && this.order.delivery_fee ? this.order.delivery_fee : 0.00
    },
    storeOrderCarriers() {
      return this.order.store_order_carriers
    },
    storeOrderCarrierStatuses() {
      return this.order.store_order_carrier_statuses
    },
  },
  async created() {
    this.carriersInfo = await this.fetchCarriersInfo();
  },
  methods: {
    async fetchCarriersInfo() {
      try {
        let carriers = await this.getCarrierInfo();
        return carriers
      } catch (e) {
        return null
      }
    },
    async getCarrierInfo() {
      if (!this.$store.getters.carriersInfoFetched) {
        if (!this.$store.state.carriersFetched) {
          this.$store.dispatch('fetchCarriers');
        }
        if (!this.$store.state.storeCarriersFetched) {
          await this.$store.dispatch('fetchStoreCarriers');
        }
      }
      const carrier = this.$store.getters.carriersInfo
      return carrier
    },
    carrierName(label) {
      if (!this.carriersInfo) {
        return ''
      } else {
        const carrier = this.carriersInfo[label]
        return carrier ? carrier.name : ''
      }
    },
    parseDate(date_str) {
      const date = new Date(date_str);
      const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      return day + '/' + month + '/' + date.getFullYear();
    },
    withBR(replace_str) {
      return replace_str ? replace_str.replace(/(?:\r\n|\r|\n)/g, '<br />') : "";
    },
    getSKU(prod_ext_id, var_sku) {
      return var_sku ? var_sku : prod_ext_id && prod_ext_id != "null" ? prod_ext_id : "";
    },
    copyProducts() {
      const products = this.order.products.map(p => ({
        'productId': p.product_id,
        'variantId': p.variant_id,
        'count': p.quantity,
        'name': p.product_name,
        'variantName': p.variant_name
      }));
      let errors = false;
      let noProds = true;
      for (var p of products) {
        const storeProd = this.$store.getters.products.find(prod => prod.id == p.productId && prod.status == 2)
        // Check if product is still found
        if (storeProd) {
          if (p.variantId) {
            // Check if product is variant
            if (storeProd.variants.find(v => v.id == p.variantId)) {
              this.$store.commit('addProductToCart', p);
              noProds = false;
            } else {
              errors = true;
              this.$toastr.e(p.name + " - " + p.variantName + " has been archived and can't be reordered.");
            }
          } else {
            this.$store.commit('addProductToCart', p);
            noProds = false;
          }
        } else {
          let prodName = p.name;
          if (p.variantName) {
            prodName += "-" + p.variantName;
          }
          errors = true;
          this.$toastr.e(prodName + " has been archived and can't be reordered.");
        }
      }
      if (!errors) {
        this.$toastr.s("Products were added to your cart");
      }
      if (!noProds) {
        const vm = this;
        setTimeout(function () {
          vm.$router.push({name: 'cart'});
        }, 1500);
      }
    },
    openInvoice() {
      let routeData = this.$router.resolve(
          {
            name: 'exportinvoice',
            params: {'storeId': this.order.store, 'orderId': this.order.id}
          });
      window.open(routeData.href, '_blank');
    },
    openDeliveryNote() {
      let routeData = this.$router.resolve(
          {
            name: 'deliverynote',
            params: {'storeId': this.order.store, 'orderId': this.order.id}
          });
      window.open(routeData.href, '_blank');
    },
    closeApprovalModal() {
      this.showApprovalModal = false;
    },
    orderApproval(data) {
      const vm = this;
      this.updating = true;
      const store = this.$store.getters.activeStore;
      let path = data.approved ? "approve" : "decline";
      this.$http({
        url: process.env.VUE_APP_API_URL + "/api/stores/" + store.id + "/orders/" + this.order.id + "/" + path + "/",
        data: {'approved': data.approved, message: data.message},
        method: 'POST'
      })
          .then(function (response) {
            let toastMsg = "This order has now been Approved. We sent an order confirmation to the selected supplier/warehouse.";
            if (data.approved) {
              // stock products were altered, so clear local copies
              vm.$store.dispatch('clearProducts')
              vm.$store.dispatch('clearLeaseProducts')
              vm.$store.dispatch('admin/clearProducts')
              vm.$store.dispatch('admin/clearLeaseProducts')
            } else {
              toastMsg = "This order has been Declined. We also sent " + vm.order.user_name + " your reply.";
            }
            vm.$store.dispatch('updateSentOrder', response.data)
            vm.order = {...response.data};
            vm.$toastr.s(
                toastMsg
            );
            vm.closeApprovalModal();
            vm.updating = false;
          })
          .catch(error => {
            vm.updating = false;
            if (error.request) {
              vm.$toastr.e(
                  error.request.responseText
              );
            }
            vm.$toastr.e(
                error
            );
          })
    },
    getOrder() {
      const vm = this;
      const orderId = this.$route.params.itemId;
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.state.activeStore.id + '/orders/' + orderId + "/";
      let method = 'GET';
      this.$http({
        method: method,
        url: api_url,
      })
          .then(function (response) {
            //  update lease order
            vm.order = response.data;
            vm.updating = false;
          })
          .catch(function (error) {
            if (error.request) {
              vm.$toastr.e(
                  error.request.responseText
              );
            }
            vm.$toastr.e(
                error
            );
            vm.updating = false;
          });
    },
    getFileName(url) {
      return url.split("/").pop();
    },

  }
}
</script>

<style scoped>
.text-underline {
  text-decoration: underline;
}

.text-gray {
  color: #9a9a9a !important;
}
</style>
